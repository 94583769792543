import React, {Fragment} from 'react';
import HorizontalMenu from "../HorizontalMenu/HorizontalMenu";

class OrdersMenu extends React.Component{
    render(){
        let items = [
            {
                to : "/billing/orders",
                className : "orders-icon",
                title : "Objednávky"
            },
            {
                to : "/billing/credit-history",
                className : "credit-history-icon",
                title : "Vyúčtování kreditu"
            }
        ];
        return (
            <Fragment>
                <HorizontalMenu items={items} pathname={this.props.pathname}/>
            </Fragment>
        )
    }
}
export default OrdersMenu;