import React, { Fragment } from 'react';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import {dropByCacheKey} from "react-router-cache-route";
import "./CreditHistory.css"
import "../components/HorizontalMenu/HorizontalMenu.css"
import Api from "../ApiLib"
import OrdersMenu from "../components/OrdersMenu/OrdersMenu";

class CreditHistory extends React.Component{
    constructor(props){
        super(props);
        this.reload = this.reload.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.state = {
            creditHistory : [],
            creditHistoryLoaded : false
        }
    }

    reload(){
        dropByCacheKey('CreditHistory');
    }

    componentDidMount() {
        Api.get("/credit-history")
            .then(res => res.json())
            .then(res => {
                if(res.authenticated){
                    this.setState({
                        creditHistory : res.credit_history,
                        creditHistoryLoaded : true
                    });
                }
            });
    }

    render(){
        let creditHistory = [];
        if(this.state.creditHistory !== null){
            this.state.creditHistory.forEach(item => {
                let updated = new Date(item.updated_time_unix * 1000);
                let price_class = (item.price <= 0) ? "red" : "green";
                let price = item.price.toString().replace('.', ',');
                creditHistory.push(
                    <tr key={item.id}>
                        <td><i>{updated.toLocaleDateString()}</i> {updated.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>
                        <td>{item.comment} {item.unit !== "p" ? `${item.value} ${item.unit}`:""}</td>
                        <td className={price_class}>{(item.price > 0) ? "+" + price : price } Kč</td>
                    </tr>
                )
            });
        }
        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <OrdersMenu pathname={this.props.router.location.pathname}/>
                    <Content>

                        <div className="section">
                            <span className="count">{this.state.creditHistory.length}</span>
                            <h1>Vyúčtování kreditu</h1>
                            {
                                this.state.creditHistoryLoaded &&
                                <div className="frame_table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Čas</th>
                                                <th>Popis</th>
                                                <th>Částka</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {creditHistory}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {
                                !this.state.creditHistoryLoaded &&
                                <div className="orders-none"><span>Načítám...</span></div>
                            }
                        </div>
                    </Content>
                </Container>
            </Fragment>
        );
    }
}
export default CreditHistory;