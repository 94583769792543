import React, { Fragment } from 'react';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import {dropByCacheKey} from "react-router-cache-route";
import { Redirect } from 'react-router-dom';
import "./Serverlets.css";
import "./ServerletCreate.css";
import Api from "../ApiLib";
import SelectList from "../components/SelectList/SelectList";

class ServerletCreate extends React.Component{
    constructor(props){
        super(props);
        props.router.cacheLifecycles.didCache(this.componentDidCache);
        props.router.cacheLifecycles.didRecover(this.componentDidRecover);
        this.create = this.create.bind(this);
        this.loadRuntimes = this.loadRuntimes.bind(this);
        this.selectPlan = this.selectPlan.bind(this);
        this.selectLang = this.selectLang.bind(this);
        this.selectRuntime = this.selectRuntime.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.componentDidRecover = this.componentDidRecover.bind(this);


        this.rightRef = React.createRef();
        this.plans = this.props.auth.get_serverlet_plans();

        let url_id = parseInt(this.props.router.match.params.plan_id);
        let plan = (this.getPlanById(this.plans, url_id)) ? url_id : this.plans[0]["id"];

        this.error_generic = "Serverlet se nepodařilo vytvořit. Kontaktujte nás prosím.";
        this.error_name_empty = "Název nesmí být prázdný";
        this.error_capacity = "Žádný server nemá volné kapacity. Zkuste to prosím později.";
        this.error_credit = "Nedostatečný kredit";
        this.error_serverlets_limit = "Dosáhly jste svého limitu počtu serverletů. Kontaktujte nás prosím.";

        this.state = {
            runtime : null,
            runtimes : [],
            plan : plan,
            langName : null,
            title : "",
            monthlyPriceTotal : (this.getPlanById(this.plans, plan)) ? this.getPlanById(this.plans, plan)["monthly_price"] : 0,
            hourlyPriceTotal : (this.getPlanById(this.plans, plan)) ? this.getPlanById(this.plans, plan)["hourly_price"] : 0,
            creating : false
        };
        window.serverlet_created = false;
        this.loadRuntimes();
    }

    getPlanById(plans, id){
        let output = null;
        plans.forEach(item => {
            if(item.id === id){
                output = item;
                return output;
            }
        })
        return output;
    }
    componentWillUnmount() {
        document.removeEventListener("scroll", this.handleScroll);
    }

    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    selectPlan(event){
        let plan_id = parseInt(event.currentTarget.getAttribute("data-id"));
        this.setState({
            plan : plan_id,
            monthlyPriceTotal : this.getPlanById(this.plans, plan_id)["monthly_price"],
            hourlyPriceTotal : this.getPlanById(this.plans, plan_id)["hourly_price"],
        });
    }
    selectLang(event){
        this.setState({
            langName : event.currentTarget.getAttribute("data-name")
        });
    }

    selectRuntime = (selectedRuntime) => {
        this.setState({
            runtime : this.state.runtimes.length > 0 ? selectedRuntime : null,
            langName : "none"
        });
    };


    loadRuntimes(){
        Api.get("/serverlets/runtimes",{})
            .then(res => res.json())
            .then(data => {
                data["runtimes"].sort((a,b) => b.version - a.version);
                this.setState({
                    runtime : data["runtimes"].length > 0 ? data["runtimes"][0] : null,
                    runtimes : data["runtimes"],
                    langName : "none"
                });
            })
            .catch((error) => {});
    }

    create(){
        this.setState({
            error : false,
            creating : true
        });
        if(this.state.title.trim().length === 0){
            this.setState({
                error : true,
                creating : false
            });
            Api.show_message(this.error_name_empty,"error");
            return;
        }
        Api.post("/serverlets",{
            title : this.state.title,
            runtime : this.state.runtime.id,
            plan : this.state.plan,
            lang : this.state.langName
        })
            .then(res => res.json())
            .then(data => {
                if(data["success"] === false){
                    let api_error_mes = data["error"]["list"][0];
                    let error = this.error_generic;
                    error = (api_error_mes === "no-free-server") ? this.error_capacity : error;
                    error = (api_error_mes === "low-credit") ? this.error_credit : error;
                    error = (api_error_mes === "serverlets_limit") ? this.error_serverlets_limit : error;
                    this.setState({
                        creating : false
                    })
                    Api.show_message(error,"error");
                }else{
                    window.serverlet_created = true;
                    dropByCacheKey('Serverlets');
                    dropByCacheKey('Settings');
                    dropByCacheKey('CreditHistory');
                    this.props.auth.enter();
                    this.setState({
                        update : true,
                        creating : false,
                        title : ""
                    })
                    Api.show_message("Serverlet byl vytvořen","success");
                }
            })
            .catch((error) => {
                this.setState({
                    error : true,
                    error_message : this.error_generic,
                    creating : false
                })
            });
    }

    componentDidCache(){
        dropByCacheKey('ServerletCreate');
        window.serverlet_created = false;
    }

    componentDidRecover() {

    }


    render(){
        if(this.state.creating){
            return(
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <Content>
                        <div className="center_info">
                            <img alt="Loader Icon" src="/img/loader-black.svg"/>
                            <h1>Vytvářím serverlet</h1>
                        </div>
                    </Content>
                </Container>
            )
        }
        if(window.serverlet_created){
            window.serverlet_created = false;
            return <Redirect to="/serverlets"/>
        }
        let plans = [];
        let langs = [];
        if(this.plans.length > 0){
            this.plans.forEach(item => {
                plans.push(
                    <div data-id={item.id} onClick={this.selectPlan} className={(this.state.plan === item.id) ? "option option-active" : "option"} key={item.id}>
                        <h1>{item["name"]}</h1>
                        <h5>{item.cpu}CPU</h5>
                        <h5>{item.ram}MB</h5>
                        <h5>{item.storage/1000}GB</h5>
                        <span className="subtitle">{item.hourly_price.toString().replace('.', ',')} Kč/h</span>
                        <span className="title">{item.monthly_price.toString().replace('.', ',')} Kč/m</span>
                    </div>
                );
            });
        }
        if(this.state.runtimes.length > 0 && this.state.runtime != null){
            this.state.runtime.langs.forEach(lang => {
                let title = lang.name;
                title = title === "none" ? "Bez jazyka" : title;
                langs.push(
                    <div data-name={lang.name} onClick={this.selectLang} className={(this.state.langName === lang.name) ? "option option-active" : "option"} key={lang.name}>
                        <img src={"/img/langs/" + lang.name + ".svg" } alt={ lang.name + " icon"}/>
                        <span className="title">{title}</span>
                    </div>
                );
            });
        }

        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <Content className="nopadding white">
                        <div className="order-form">
                            <div className="section">
                                <h1 className="padded">Výběr názvu</h1>
                                <span className="count">1</span>
                                <span className="inner-frame">
                                    <input type="text" onChange={this.handleInputChange} name="title" value={this.state.title} placeholder="Můj první serverlet" required/>
                                </span>
                            </div>
                            <div className="section">
                                <h1 className="padded">Výběr tarifu</h1>
                                <span className="count">2</span>
                                <span className="inner-frame">
                                    {plans}
                                </span>
                            </div>
                            <div className="section">
                                <h1 className="padded">Výběr obrazu</h1>
                                <span className="count">3</span>
                                <span className="inner-frame">
                                    <SelectList
                                        options={this.state.runtimes}
                                        labelName="name"
                                        valueName="id"
                                        selectedOption={this.state.runtime}
                                        onChange={this.selectRuntime}
                                        noMessage="Obraz nenalezen"
                                        placeholder="Obraz"
                                    />
                                </span>
                            </div>
                            <div className="section">
                                <h1 className="padded">Výběr jazyka</h1>
                                <span className="count">4</span>
                                <span className="inner-frame">
                                    {langs}
                                </span>
                            </div>
                            <div className="delimiter"/>

                            <div className="section">
                                <span className="description">Nevíte-li jakou položku zvolit, ponechejte prosím výchozí.</span>

                                <span className="description">Částka {this.state.hourlyPriceTotal.toString().replace('.', ',')} Kč bude stržena z Vašeho kreditu nyní a poté každou započatou hodinu do ukončení služby smazáním serverletu.</span>
                                <span className="description">
                                    Vypnutý serverlet je účtován stejně jak zapnutý, neboť z naší strany stále dochází rezervování potřebných zdrojů.
                                    Měsíční částka představuje maximální částku, kterou součet nákladů serverletu za kalendářní měsíc při hodinovém účtování nemůže překročit.
                                </span>

                            </div>
                        </div>
                        <div className="order-summary" ref={this.rightRef}>
                            <div className="panel">
                                    <ul>
                                        <li>
                                            <span className="title">Tarif:</span>
                                            <span className="value">{this.getPlanById(this.plans, this.state.plan)["name_prefix"]} {this.getPlanById(this.plans, this.state.plan)["name"]}</span>
                                        </li>
                                        <li>
                                            <span className="title"><strong>Cena:</strong><i>&nbsp;{this.state.hourlyPriceTotal.toString().replace('.', ',')} Kč/h&nbsp; ~</i></span>
                                            <span className="value">{this.state.monthlyPriceTotal.toString().replace('.', ',')} Kč / měsíčně</span>
                                        </li>
                                    </ul>
                                <Fragment>
                                    {
                                        this.state.hourlyPriceTotal > this.props.auth.user.credit &&
                                        <span className="order-error">Nedostatečný kredit</span>
                                    }
                                    {
                                        this.state.hourlyPriceTotal <= this.props.auth.user.credit &&
                                        <button onClick={this.create} className="order">Vytvořit</button>
                                    }
                                </Fragment>

                            </div>
                        </div>
                    </Content>
                </Container>
            </Fragment>
        );
    }
}
export default ServerletCreate;