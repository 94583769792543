import React, { Fragment } from 'react';
import './Chat.css';
import Api from "../../ApiLib";

class Chat extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            show : false
        }
        this.onChatReady = this.onChatReady.bind(this);
        this.onChatDomLoad = this.onChatDomLoad.bind(this);
        this.showChat = this.showChat.bind(this);
        this.hideChat = this.hideChat.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    showChat(){
        this.setState({
            show : true
        })
    }

    hideChat(){
        this.setState({
            show : false
        })
    }

    componentDidMount () {
        document.addEventListener("support-open", this.showChat);
        document.addEventListener("logout", this.onLogout);

        document.addEventListener("tidioChat-ready", this.onChatReady);
        document.addEventListener("tidioChat-open", this.onChatDomLoad);
    }

    componentWillUnmount() {
        document.removeEventListener("support-open", this.showChat);
        document.removeEventListener("logout", this.onLogout);

        document.removeEventListener("tidioChat-ready", this.onChatReady);
        document.removeEventListener("tidioChat-open", this.onChatDomLoad);
    }

    onLogout(){
        this.hideChat();
        for (let key in localStorage){
            if(key.includes("tidio_")){
                localStorage.removeItem(key);
            }
        }

        let tidioChat = document.getElementById("tidio-chat");
        if(tidioChat){
            document.body.removeChild(tidioChat);
        }
        let tidioChatCode = document.getElementById("tidio-chat-code");
        if(tidioChatCode){
            document.body.removeChild(tidioChatCode);
        }
        let tidioScript = document.getElementById("tidio-script");
        if(tidioScript){
            document.body.removeChild(tidioScript);
        }
        window.tidioChatApi = null;
    }

    onChatReady(e){
        document.dispatchEvent(
            new Event("support-ready")
        );
        this.onChatDomLoad();
        if (window.tidioChatApi) {
            window.tidioChatApi.setVisitorData({
                distinct_id: this.props.auth.user.id
            });
            window.tidioChatApi.setContactProperties({
                client_version : Api.client.version.toString(),
                adm_version : this.props.auth.version.toString(),
                user_id: this.props.auth.user.id,
                credit: this.props.auth.user.credit,
                address: `${this.props.auth.user.contact.street} ${this.props.auth.user.contact.street_no}, ${this.props.auth.user.contact.postal_code} ${this.props.auth.user.contact.city}`
            });
            window.tidioChatApi.open();
        }
    }

    onChatDomLoad(){
        let iframeDocument;
        try{
            iframeDocument = document.getElementById("tidio-chat-iframe").contentWindow.document;
            iframeDocument.querySelector("#button-body").style.background="#fbc531";

            iframeDocument.querySelector(".offline-message").style.background="#fcd465";
            iframeDocument.querySelector(".chat-header").style.background="#fff";
            iframeDocument.querySelector(".footer-bottom a").style.display="none";
            document.getElementById("tidio-chat-iframe").style.display="block";
        }catch{
            setTimeout(() => {
                this.onChatDomLoad()
            }, 0)
        }
    }

    render(){
        if(this.props.auth.authenticated && this.state.show) {
            document.tidioIdentify = {
                distinct_id: this.props.auth.user.id,
                email: this.props.auth.user.email,
                name: this.props.auth.user.contact.personal === 1 ? `${this.props.auth.user.contact.firstname} ${this.props.auth.user.contact.lastname}` : this.props.auth.user.contact.company
            };

            let script = document.createElement("script");
            script.src = "//code.tidio.co/oqdi81ioqvmq00bxyzuxvzuzml3frstq.js";
            script.id = "tidio-script";
            script.async = true;
            document.body.appendChild(script);
        }
        return <Fragment/>
    }
}
export default Chat;