import React from 'react';
import "./DomainDnsItem.css"
import {dropByCacheKey} from "react-router-cache-route";
import { Link } from 'react-router-dom';

class DomainDnsItem extends React.Component{
    componentDidCache(){
        dropByCacheKey('DomainDnsItem');
    }
    render(){
        //let updated_time = new Date(this.props.data.updated_time_unix * 1000);
        return (
            <Link className="domains-dns-item" to={ "/domain/dns/" +  this.props.data.domain } style={{ textDecoration: 'none' }}>
                <span className="title">{this.props.data.domain}</span>
            </Link>
        )
    }
}
export default DomainDnsItem;