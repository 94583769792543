import React, { Fragment } from 'react';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import {dropByCacheKey} from "react-router-cache-route";
import { Redirect } from 'react-router-dom';
import {Link} from "react-router-dom";
import "../containers/Content.css";
import "./CreditOrder.css";
import Api from "../ApiLib";

class ServerletCreate extends React.Component{
    constructor(props){
        super(props);

        this.countries = {
            "cz":"Česká republika",
            "sk":"Slovensko"
        };
        props.router.cacheLifecycles.didCache(this.componentDidCache);
        props.router.cacheLifecycles.didRecover(this.componentDidRecover);
        this.componentDidRecover = this.componentDidRecover.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.numAdd = this.numAdd.bind(this);
        this.numSub = this.numSub.bind(this);
        this.numChange = this.numChange.bind(this);
        this.order = this.order.bind(this);
        this.useVoucher = this.useVoucher.bind(this);
        this.selectPayment = this.selectPayment.bind(this);
        this.getCurrentPayment = this.getCurrentPayment.bind(this);
        this.handleVoucherChange = this.handleVoucherChange.bind(this);

        this.success_voucher = "Voucher byl úspěšně použit.";
        this.error_voucher = "Tento voucher neexistuje";
        this.error_voucher_expired = "Voucher není platný";
        this.error_voucher_too_early = "Počkejte prosím několik vteřin před použitím dalšího voucheru.";
        this.error_voucher_used = "Tento poukaz byl již použit.";

        this.plans = this.props.auth.get_serverlet_plans();
        this.state = {
            ordering : false,
            credit : 0,
            payment : 0,
            voucherCode : "",
            done : false
        };

        this.payments = [
            {
                id : 0,
                title:"Bankovní převod",
                img : "payment-bank-transfer.svg"
            },
            {
                id : 1,
                title:"Platební karta",
                img : "credit-card.svg"
            },
            {
                id : 2,
                title:"Dárkový poukaz",
                img : "gift-card.svg"
            }
        ]
    }

    componentWillUnmount() {
    }

    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    componentDidCache(){
        dropByCacheKey('CreditOrder');
    }

    componentDidRecover() {

    }


    numAdd(){
        let new_credit = this.state.credit + 10;
        new_credit = (this.state.credit.length === 0) ? 0 : new_credit;
        if(new_credit <= 10000){
            this.setState({
                credit : new_credit
            })
        }else{
            this.setState({
                credit : 10000
            })
        }
    }

    numSub(){
        let new_credit = this.state.credit - 10;
        new_credit = (this.state.credit.length === 0) ? 0 : new_credit;
        if(new_credit >= 0){
            this.setState({
                credit : new_credit
            })
        }else{
            this.setState({
                credit : 0
            })
        }
    }

    numChange(event){
        if(event.target.value.length === 0){
            this.setState({
                credit : ""
            })
        }else{
            let new_credit = (parseInt(event.target.value)) ? parseInt(event.target.value) : 0;
            if(new_credit >= 0 && new_credit <= 10000){
                this.setState({
                    credit : new_credit
                })
            }
        }
    }

    selectPayment(event){
        let id = parseInt(event.target.getAttribute("data-id"));
        this.setState({
            payment : id,
            credit : (id === 2) ? 0 : this.state.credit,
            voucherCode : "",
        })
    }

    getCurrentPayment(){
        let payment = null;
        this.payments.forEach(item => {
            if(item.id === this.state.payment){
                payment = item;
            }
        });
        return payment;
    }

    order(event){
        Api.post(`/orders`,{
            credit : this.state.credit,
            payment_method : this.state.payment
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    dropByCacheKey('Orders');
                    dropByCacheKey('CreditHistory');
                    this.setState({
                        done : true
                    })
                    dropByCacheKey('CreditOrder');
                    Api.show_message("Objednávka vytvořena.", "success");
                }else{
                    Api.show_message("Objednávku se nepodařilo vytvořit.", "error");
                }
            });
    }

    useVoucher(){
        Api.post(`/orders/voucher`,{
            voucher : this.state.voucherCode
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    dropByCacheKey('Orders');
                    dropByCacheKey('CreditHistory');
                    this.setState({
                        done : true
                    })
                    dropByCacheKey('CreditOrder');
                    this.props.auth.enter();
                    Api.show_message(this.success_voucher, "success");
                }else{
                    if(res.error){
                        if(res.error.list[0] === "too-early"){
                            Api.show_message(this.error_voucher_too_early, "info");
                            return;
                        }
                        let mes = this.error_voucher;
                        mes = (res.error.list[0] === "voucher-expired") ? this.error_voucher_expired : mes;
                        mes = (res.error.list[0] === "voucher-used") ? this.error_voucher_used : mes;
                        Api.show_message(mes, "error");
                    }
                }
            });
    }

    handleVoucherChange(event){
        let code = event.target.value.toUpperCase();
        if(code.length >= 20){
            this.setState({
                voucherCode : code,
                credit : this.getVoucherPrice(code)
            });
            return;
        }
        this.setState({
            voucherCode : code,
            credit : 0
        });
    }

    letterToDigit(letter){
        let keyMap = {
            "g" : "0",
            "p" : "1",
            "y" : "2",
            "u" : "3",
            "z" : "4",
            "n" : "5",
            "a" : "6",
            "k" : "7",
            "i" : "8",
            "t" : "9"
        };
        if (letter in keyMap){
            return keyMap[letter];
        }
        return "x";
    }

    getVoucherPrice(voucher){
        voucher = voucher.toLowerCase();
        if(voucher.length >= 20){
            try{
                let priceText = "";
                priceText += this.letterToDigit(voucher[2]);
                priceText += this.letterToDigit(voucher[5]);
                priceText += this.letterToDigit(voucher[10]);
                priceText += this.letterToDigit(voucher[13]);
                return parseInt(priceText);
            }catch (e) {}
        }
        return 0;
    }

    render(){
        let payments = [];
        let i = 0;
        this.payments.forEach(item => {
            payments.push(
                <div onClick={this.selectPayment} data-id={item.id} className={(this.state.payment === i) ? "option option-active":"option"} key={i++}>
                    <img src={"/img/" + item.img } data-id={item.id} alt="Option icon"/>
                    <span className="title" data-id={item.id}>{item.title}</span>
                    {
                        (item.id === 1) &&
                        <span className="info">Dočasně nedostupné</span>
                    }
                </div>
            )
        });
        if(this.state.done){
            return <Redirect to="/billing/orders"/>
        }
        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <Content className="nopadding white">
                        <div className="order-form">
                            {
                                this.state.payment !== 2 &&
                                <div className="section">
                                    <h1 className="padded">Výběr částky</h1>
                                    <span className="count">1</span>
                                    <div className="inner-frame">
                                        <div className="number-input">
                                            <button onClick={this.numSub} className="left-input">-</button>
                                            <input onChange={this.numChange} type="text" value={this.state.credit}/>
                                            <button onClick={this.numAdd} className="right-input">+</button>
                                            <span className="label">Kč</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.payment === 2 &&
                                <div className="section">
                                    <h1 className="padded">Výběr dárkového poukazu</h1>
                                    <span className="count">1</span>
                                    <div className="inner-frame">
                                        <input className="gift-card" onChange={this.handleVoucherChange} type="text" name="voucherCode" value={this.state.voucherCode} placeholder="Kód poukazu" maxLength="100"/>
                                    </div>
                                </div>
                            }
                            <div className="section">
                                <h1 className="padded">Výběr způsobu platby</h1>
                                <span className="count">2</span>
                                <div className="inner-frame">
                                    <div className="options">
                                        {payments}
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <h1 className="padded">Odběratel</h1>
                                <span className="count">3</span>
                                <div className="contact">
                                    <Link to="/settings/contact" className="edit"/>
                                    {
                                        Boolean(this.props.auth.user.contact.personal) &&
                                        <h2 className="contact-title">{this.props.auth.user.contact.firstname} {this.props.auth.user.contact.lastname}</h2>
                                    }
                                    {
                                        !Boolean(this.props.auth.user.contact.personal) &&
                                        <h2 className="contact-title">{this.props.auth.user.contact.company}</h2>
                                    }
                                    <p>{this.props.auth.user.contact.street} {this.props.auth.user.contact.street_no}</p>
                                    <p>{this.props.auth.user.contact.postal_code} {this.props.auth.user.contact.city}</p>
                                    <p>{this.countries[this.props.auth.user.contact.country]}</p>
                                    {
                                        !Boolean(this.props.auth.user.contact.personal) &&
                                        <div className="other">
                                            <p>IČ: {this.props.auth.user.contact.ic}</p>
                                            <p>DIČ: {this.props.auth.user.contact.dic}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="section">
                                {
                                    (this.state.payment !== 2) &&
                                    <span className="description">Objednávka je zcela nezávazná a do zaplacení může být kdykoliv později zrušena. Objednáním potvrzujete, že všechny Vámi vyplněné údaje jsou pravdivé.</span>
                                }
                                {
                                    (this.state.payment === 2) &&
                                    <span className="description">Kredit z poukazu lze uplatnit na jakékoliv naše služby.</span>
                                }
                            </div>
                        </div>
                        <div className="order-summary">
                            <div className="panel">
                                <ul>
                                    {
                                        (this.state.payment !== 2) &&
                                    <li className="dollar-icon">
                                        <span className="title">Kredit</span>
                                        <span className="value">{(this.state.credit) ? this.state.credit : 0 }</span>
                                    </li>
                                    }
                                    <li>
                                        <span className="title">
                                            <strong>{this.state.payment === 2 ? "Hodnota poukazu" : "Cena"}</strong>
                                        </span>
                                        <span className="value">{(this.state.credit) ? this.state.credit : 0 } Kč</span>
                                    </li>
                                </ul>
                                {
                                    (this.state.credit <= 0 && this.state.payment !== 2) &&
                                    <span className="order-error">Nízká částka</span>
                                }
                                {
                                    (this.state.credit > 0 && this.state.payment !== 2) &&
                                    <button onClick={this.order} className="order">Objednat</button>
                                }
                                {
                                    (this.state.payment === 2 && (this.state.credit <= 0 || Number.isNaN(this.state.credit))) &&
                                    <span className="order-error">Neplatný poukaz</span>
                                }
                                {
                                    (this.state.payment === 2 && this.state.credit > 0) &&
                                    <button onClick={this.useVoucher} className="order">Uplatnit poukaz</button>
                                }
                            </div>
                        </div>
                    </Content>
                </Container>
            </Fragment>
        );
    }
}
export default ServerletCreate;