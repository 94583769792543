import React, {Fragment} from 'react';
import './VersionInfo.css';
import Api from "../../ApiLib";

class VersionInfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            show : false
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event){
        if(event.target.getAttribute("data-name") === "version-info"){
            this.setState({
                show : !this.state.show
            })
        }
    }

    render(){
        return (
            <Fragment>
                <div data-name="version-info" onClick={this.handleClick} className={this.state.show ? "version-info version-info-opened" : "version-info"}>
                    <span data-name="version-info" className="info">© {new Date().getFullYear()} Futrou</span>
                    <div className="menu">
                        <ul>
                            <li><h3>{Api.client.name}</h3></li>
                            <li>verze: {Api.client.version}</li>
                            <li>{Api.client.build_date.toLocaleString()}</li>
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default VersionInfo;