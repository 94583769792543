import metadata from "./metadata.json";

class Api{
    static server_url = "https://adm_api.futrou.com";
    static client = {
        name : metadata.name,
        version : `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`,
        build_date : new Date(metadata.buildDateTime * 1000),
        changelog : metadata.changelog
    }
    static credentials = 'include';
    static headers = {
        'Content-Type': 'application/json',
        'X-Requested-With' : 'FUTROU_ADM'
    };
    static get(url) {
        const requestOptions = {
            method: 'GET',
            credentials: Api.credentials,
            headers: Api.headers,
        };
        return fetch(Api.server_url + url, requestOptions);
    }
    static post(url, body = {}) {
        const requestOptions = {
            method: 'POST',
            credentials: Api.credentials,
            headers: Api.headers,
            body: JSON.stringify(body)
        };
        return fetch(Api.server_url + url, requestOptions);
    }

    static put(url, body) {
        const requestOptions = {
            method: 'PUT',
            credentials: Api.credentials,
            headers: Api.headers,
            body: JSON.stringify(body)
        };
        return fetch(Api.server_url + url, requestOptions);
    }

    static delete(url) {
        const requestOptions = {
            method: 'DELETE',
            credentials: Api.credentials,
            headers: Api.headers,
        };
        return fetch(Api.server_url + url, requestOptions);
    }

    static show_message(message, message_type){
        let event = new CustomEvent('page_message', {
            detail: {
                message: message,
                message_type : message_type
            }
        });
        document.dispatchEvent(event);
    }

    static show_alert(message, func){
        let event = new CustomEvent('page_alert', {
            detail: {
                message: message,
                function : func
            }
        });
        document.dispatchEvent(event);
    }

}
export default Api;