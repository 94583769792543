import React, { Fragment } from 'react';
import {dropByCacheKey} from "react-router-cache-route";
import "./Serverlets.css"
import Api from "../ApiLib"

class ServerletDomains extends React.Component{
    constructor(props){
        super(props);
        this.reload = this.reload.bind(this);
        this.state = {
            new_domain : ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.deleteDomain = this.deleteDomain.bind(this);
        this.addDomain = this.addDomain.bind(this);

        this.success_domain_added = "Doména přidána.";
        this.success_domain_deleted = "Doména smazána.";
        this.error_domain_generic = "Doménu se nepodařil přidat.";
        this.error_domain_exist = "Doména je již připojena k jinému serverletu. Kontaktujte nás prosím.";
        this.error_domain_format = "Chybný formát domény.";
        this.error_domain_tld = "Neznámá TLD koncovka domény.";
        this.error_domain_empty = "Zadejte název domény.";
        this.error_domain_prohibited = "Použití této domény je zakázáno. Kontaktujte nás prosím.";
        this.error_domain_deleted = "Doménu se nepodařilo smazat.";
        this.error_domain_too_early = "Počkejte několik vteřin po provedení předchozí akce.";

    }

    reload(){
        dropByCacheKey('Serverlet');
    }

    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    addDomain(){
        Api.post(`/serverlet/${this.props.parent.state.params.id}/domains`, {
            domain : this.state.new_domain
        }).then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    Api.show_message(this.success_domain_added, "success");
                    this.props.parent.getDomains();
                    this.setState({ new_domain : "" })
                }else{
                    if(res.error){
                        let mes = this.error_domain_generic;
                        mes = (res.error.list[0] === "empty") ? this.error_domain_empty: mes;
                        mes = (res.error.list[0] === "wrong-format") ? this.error_domain_format : mes;
                        mes = (res.error.list[0] === "invalid-length") ? this.error_domain_format : mes;
                        mes = (res.error.list[0] === "tld-not-exists") ? this.error_domain_tld : mes;
                        mes = (res.error.list[0] === "domain-exist") ? this.error_domain_exist : mes;
                        mes = (res.error.list[0] === "prohibited-domain") ? this.error_domain_prohibited : mes;
                        Api.show_message(mes, "error");
                    }
                }
            });
    }

    deleteDomain(event){
        let domain = event.target.getAttribute("data-domain");
        Api.delete(`/serverlet/${this.props.parent.state.params.id}/domains/${domain}`, {
        }).then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    Api.show_message(this.success_domain_deleted, "success");
                    this.props.parent.getDomains();
                }else{
                    if(res.error){
                        let mes = this.error_domain_deleted;
                        mes = (res.error.list[0] === "too-early") ? this.error_domain_too_early : mes;
                        Api.show_message(mes, "error");
                    }
                }
            });
    }

    render(){
        let domains = [];
        if(this.props.domains.length > 0 && this.props.serverlet !== null){
            this.props.domains.forEach(item => {
                domains.push(
                    <tr key={item.id}>
                        <td>{item.name}</td>
                        <td className="right">
                            <span className={(item.cert_issued)? "cert":"no-cert"}/>
                            {
                                this.props.serverlet.state === 1  &&
                                <Fragment>
                                    {
                                        item.internal !== 1  &&
                                        <button onClick={this.deleteDomain} data-domain={item.name} className="delete-button"/>
                                    }
                                </Fragment>
                            }
                        </td>
                    </tr>
                );
            });
        }
        return (
            <Fragment>
                <div className="section">
                    <h1 className="padded">Domény</h1>
                    <span className="count">{this.props.domains.length}</span>
                    {
                        !this.props.domainsLoaded &&
                        <div className="inner-none"><span>Načítám...</span></div>
                    }
                    {
                        this.props.domainsLoaded &&
                        <Fragment>
                            {
                                this.props.domains.length === 0 &&
                                <div className="inner-none"><span>Žádné domény</span></div>
                            }
                            {
                                this.props.domains.length > 0 &&
                                <div className="inner-frame domains-list">
                                    <div className="list">
                                        <table>
                                            <thead>
                                            <tr>
                                                <td>Název</td>
                                                <td></td>
                                            </tr>
                                            </thead>
                                            <tbody>{domains}</tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                (this.props.serverlet && this.props.serverlet.state === 1) &&
                                <div className="inner-input">
                                    <input type="text" onChange={this.handleInputChange} value={this.state.new_domain} name="new_domain" className="list-text" placeholder="Doména"/>
                                    <input type="submit" onClick={this.addDomain} name="add_domain" className="list-submit" value="Přidat"/>
                                    <span className="info">Např.: mojedomena.cz</span>
                                </div>
                            }
                            {
                                (this.props.serverlet && this.props.serverlet.state === 0) &&
                                <div className="full-warning">Pro úpravu domén musí být serverlet zapnutý</div>
                            }
                        </Fragment>
                    }
                </div>
            </Fragment>
        );
    }
}
export default ServerletDomains;