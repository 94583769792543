import React, { Fragment } from 'react';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import Api from "../ApiLib";
import "./DomainDNS.css"
import DomainsMenu from "../components/DomainsMenu/DomainsMenu";

class DomainDNS extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            domain_dns : null,
            params : props.router.match.params,
            type : "a",
            name : "",
            value : "",
            ttl : 1,
            priority : null
        }

        window.domain_dns = this;
        props.router.cacheLifecycles.didRecover(this.componentDidRecover);

        this.handleSelect = this.handleSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.add_record = this.add_record.bind(this);
        this.delete_record = this.delete_record.bind(this);
        this.load();

        this.ttlStrings = {
            1 : "Auto",
            300 : "5min",
            600 : "10min",
            900 : "15min",
            1800 : "30min",
            3600 : "1h",
            7200 : "2h",
            28800 : "8h",
            43200 : "12h",
            86400: "24h"
        };
    }

    componentDidRecover(){
        window.domain_dns.load();
    }

    componentDidMount() {

    }

    getValuePlaceholder(){
        if(this.state.type === "A"){
            return "IPv4 adresa";
        }
        if(this.state.type === "AAAA"){
            return "IPv6 adresa";
        }
        if(this.state.type === "CNAME"){
            return "Alias doména";
        }
        if(this.state.type === "MX"){
            return "Emailový server";
        }
        if(this.state.type === "TXT"){
            return "Poznámka";
        }
        return "Hodnota";
    }

    handleSelect(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    getTTLString(ttl){
        if(this.ttlStrings[ttl] !== undefined){
            return this.ttlStrings[ttl];
        }
        return ttl;
    }

    load(){
        Api.get(`/domain/dns/${this.props.router.match.params.domain}`)
            .then(response => response.json())
            .then(data => {
                if(data.success && data.authenticated === true){
                    if(data.domain_dns){
                        //data.domain_dns.records = data.domain_dns.records.reverse()
                        this.setState({
                            domain_dns : data.domain_dns
                        });
                    }
                }
            });
    }

    isNumeric(num){
        return !isNaN(num)
    }

    add_record(){
        if(
            this.state.name.length > 0 &&
            this.state.name.length < 253 &&
            this.state.value.length > 0 &&
            this.state.value.length < 253 &&
            this.isNumeric(this.state.priority) &&
            this.state.priority >= 0 &&
            this.state.priority <= 65535
        ){
            Api.post(`/domain/dns/${this.props.router.match.params.domain}`,{
                type : this.state.type,
                name : this.state.name,
                value : this.state.value,
                ttl : this.state.ttl,
                priority : parseInt(this.state.priority)
            })
                .then(response => response.json())
                .then(data => {
                    if(data.success && data.authenticated === true){
                        Api.show_message("Záznam přidán", "success");
                        this.setState({
                            name : "",
                            value : "",
                            ttl : 1
                        })
                        this.load();
                    }else{
                        Api.show_message("Záznam se nepodařilo přidat", "error");
                    }
                });
        }else{
            Api.show_message("Chybný vstup", "error");
        }
    }

    delete_record(event){
        let id = event.target.getAttribute('data-id');
        Api.delete(`/domain/dns/${this.props.router.match.params.domain}/${id}`,{
        })
            .then(response => response.json())
            .then(data => {
                if(data.success && data.authenticated === true){
                    Api.show_message("Záznam odstraněn", "success");
                    this.setState({
                        name : "",
                        value : "",
                        ttl : 1,
                        priority : null
                    })
                    this.load();
                }else{
                    Api.show_message("Záznam se nepodařilo odstranit", "error");
                }
            });
    }

    render(){
        let records = [];
        if(this.state.domain_dns !== null){
            Object.values(this.state.domain_dns.records).forEach(item => {
                records.push(
                    <tr key={item.id} id={item.id}>
                        <td className="type">{item.type}</td>
                        <td className="name">{item.name}</td>
                        <td className="value">{item.priority ? <span className="span-priority">{item.priority}</span> : ""}{item.value}</td>
                        <td className="ttl"><span className="span-ttl">{this.getTTLString(item.ttl)}</span></td>
                        <td className="edit">
                            <button data-id={item.id} className="edit-button"/>
                        </td>
                        <td className="delete">
                            <button data-id={item.id} onClick={this.delete_record} className="delete-button"/>
                        </td>
                    </tr>
                );
            });
        }

        let options = [];
        Object.entries(this.ttlStrings).forEach(([key, value]) => {
            options.push(
                <option value={key}>{value}</option>
            );
        })
        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <DomainsMenu pathname={this.props.router.location.pathname}/>
                    <Content className="domain_dns_content">
                        <div className="section">
                            <h1 className="padded">Zóna {this.props.router.match.params.domain}</h1>

                            <div className="domain-dns-input">
                                <select className="dns-type" name="type" onChange={this.handleSelect}>
                                    <option value="A" selected={this.state.type === "A"}>A</option>
                                    <option value="AAAA" selected={this.state.type === "AAAA"}>AAAA</option>
                                    <option value="CNAME" selected={this.state.type === "CNAME"}>CNAME</option>
                                    <option value="MX" selected={this.state.type === "MX"}>MX</option>
                                    <option value="TXT" selected={this.state.type === "TXT"}>TXT</option>
                                </select>
                                <input type="text" name="name" onChange={this.handleChange} className={this.state.type === "TXT" ? "dns-name-large" : "dns-name"} placeholder="Název" value={this.state.name}/>
                                <input type="text" name="value" onChange={this.handleChange} className={this.state.type === "TXT" ? "dns-value-none" : (this.state.type === "MX" ? "dns-value-small" : "dns-value")} placeholder={this.getValuePlaceholder()} value={this.state.value}/>
                                <input type="text" name="priority" onChange={this.handleChange} className={this.state.type !== "MX" ? "dns-priority-none" : "dns-priority"} placeholder="Priorita" value={this.state.priority}/>

                                <select className="dns-ttl" name="ttl" onChange={this.handleSelect}>
                                    {options}
                                </select>

                                <input type="submit" name="add_domain" className="list-submit" value="Přidat" onClick={this.add_record}/>

                            </div>
                            {
                                this.state.type === "TXT" &&
                                <div className="domain-dns-input">
                                    <textarea name="value" onChange={this.handleChange} className="dns-textarea" placeholder={this.getValuePlaceholder()} value={this.state.value}/>
                                </div>
                            }

                            <div className="inner-frame domain-dns-list">
                                <div className="list">
                                    <table>
                                        <thead>
                                        <tr>
                                            <td className="type">Typ</td>
                                            <td className="name">Název</td>
                                            <td>Hodnota</td>
                                            <td className="ttl">TTL</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {records}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Container>
            </Fragment>
        )
    }
}
export default DomainDNS;