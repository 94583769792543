import React, { Fragment } from 'react';
import Footer from '../components/Footer/Footer'
import './Error.css';

export const Error500 = (props) => {
    return (
        <Fragment>
            <div className="error-page">
                <h1>500</h1>
                <h2>Interní chyba serveru</h2>
                <p>Zdá se, že se nám něco pokazilo. Zkuste to prosím později.</p>
            </div>
            <Footer/>
        </Fragment>
    );
}
export default Error500;