import React from 'react';
import { Redirect} from 'react-router-dom';
import { dropByCacheKey } from 'react-router-cache-route'
import "./Logout.css"

class Logout extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loaded : false
        }
        props.router.cacheLifecycles.didCache(this.componentDidCache);
        props.auth.logout().then(res => {});
    }

    componentDidMount() {
        document.dispatchEvent(
            new Event("logout")
        );
    }

    componentDidCache(){
        dropByCacheKey('Logout');
    }

    render(){
        if(this.state.loaded){
            return (<Redirect to="/login"/>);
        }else{
            return (<div className="logging-out">Odhlašuji...</div>);
        }
    }
}
export default Logout;