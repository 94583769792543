import React, { Fragment } from 'react';
import ServerletItem from '../components/ServerletItem/ServerletItem';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import {dropByCacheKey} from "react-router-cache-route";
import {Link} from "react-router-dom";
import "./Serverlets.css"
import Api from "../ApiLib"

class Serverlets extends React.Component{
    constructor(props){
        super(props);
        this.reload = this.reload.bind(this);
        this.state = {
            serverlets : null,
            auth : true
        }
    }

    reload(){
        dropByCacheKey('Serverlets');
    }

    componentDidMount() {
        Api.get("/serverlets")
            .then(response => response.json())
            .then(data => {
                if(data.authenticated !== true){
                    this.setState({
                        auth: false
                    });
                }else{
                    if(data.serverlets){
                        this.setState({
                            serverlets : data.serverlets
                        });
                    }
                }
            });
    }

    render(){
        let serverlets = [];
        let shared_serverlets = [];
        if(this.state.serverlets !== null){
            let i = 0;
            this.state.serverlets.forEach(item => {
                if(item.permission.owner === 1){
                    serverlets.push(
                        <ServerletItem key={i++} data={item}/>
                    );
                }else{
                    shared_serverlets.push(
                        <ServerletItem key={i++} data={item}/>
                    );
                }
            });
        }
        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <Content>
                        <div className="section">
                            <h1 className="padded">Mé serverlety</h1>
                            <span className="count">{serverlets.length}</span>
                            <Link to="/serverlets/create" className="create">Vytvořit</Link>
                            <div className={serverlets.length > 0 ? "serverlets-list" : "serverlets-list serverlets-none" }>
                                {serverlets.length < 1 && <span>Žádné serverlety</span>}
                                {serverlets}
                            </div>
                        </div>
                        <div className="section">
                            <h1 className="padded">Sdílené serverlety</h1>
                            <span className="count">{shared_serverlets.length}</span>
                            <div className={shared_serverlets.length > 0 ? "serverlets-list" : "serverlets-list serverlets-none" }>
                                {shared_serverlets.length < 1 && <span>Žádné serverlety</span>}
                                {shared_serverlets}
                            </div>
                        </div>

                    </Content>
                </Container>
            </Fragment>
        );
    }
}
export default Serverlets;