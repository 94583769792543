import React, { Fragment } from 'react';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import Api from "../ApiLib";
import Chat from "../components/Chat/Chat";

class Support extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <Content>
                        <Chat auth={this.props.auth}/>
                    </Content>
                </Container>
            </Fragment>
        )
    }
}
export default Support;