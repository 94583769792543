import React, { Fragment } from 'react';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import {dropByCacheKey} from "react-router-cache-route";
import DomainsMenu from "../components/DomainsMenu/DomainsMenu";

class Domains extends React.Component{
    constructor(props){
        super(props);
        props.router.cacheLifecycles.didCache(this.componentDidCache);
    }
    componentDidCache(){
        dropByCacheKey('Domains');
    }
    render(){
        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <DomainsMenu pathname={this.props.router.location.pathname}/>
                    <Content>
                        <div className="section">
                            <h1 className="padded">Domény</h1>
                            <span className="count">0</span>
                            <div className="serverlets-list serverlets-none">
                                <span>Žádné domény</span>
                            </div>
                        </div>
                    </Content>
                </Container>
            </Fragment>
        )
    }
}
export default Domains;