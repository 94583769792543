import React, { Fragment } from 'react';
import "./Serverlet.css";
import "./Settings.css";
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import {dropByCacheKey} from "react-router-cache-route";
import { Link } from 'react-router-dom';
import { UAParser } from 'ua-parser-js';
import Api from "../ApiLib";
import SelectList from "../components/SelectList/SelectList";

class Settings extends React.Component{
    constructor(props){
        super(props);

        this.countryOptions = [
            { value: 'cz', label: 'Česká republika' },
            { value: 'sk', label: 'Slovensko' }
        ];

        this.pageRef = React.createRef();
        window.serverlets_sidemenu_hide = true;
        this.loadDevices = this.loadDevices.bind(this);
        this.logoutFromDevices = this.logoutFromDevices.bind(this);
        this.getItemClass = this.getItemClass.bind(this);
        props.router.cacheLifecycles.didCache(this.componentDidCache);
        props.router.cacheLifecycles.didRecover(this.componentDidRecover);
        props.router.match.params["page"] = (props.router.match.params["page"]) ? props.router.match.params["page"] : "email";

        this.state = {
            loaded : false,
            checkbox : true,
            params : props.router.match.params,
            newEmail : "",
            password : "",
            newPassword : "",
            newPasswordAgain : "",
            personal: (this.props.auth.user.contact.personal === 1),
            firstname: this.props.auth.user.contact.firstname,
            lastname: this.props.auth.user.contact.lastname,
            company: (this.props.auth.user.contact.company) ? this.props.auth.user.contact.company : "",
            street: this.props.auth.user.contact.street,
            streetNo: this.props.auth.user.contact.street_no,
            postalCode: this.props.auth.user.contact.postal_code,
            city: this.props.auth.user.contact.city,
            country: this.props.auth.user.contact.country,
            ic: (this.props.auth.user.contact.ic) ? this.props.auth.user.contact.ic : "",
            dic: (this.props.auth.user.contact.dic) ? this.props.auth.user.contact.dic : "",
            devices : [],
            devicesLoaded : false,
            limits : [],
            limitsLoaded : false,
            selectedCountry: this.getSelectedCountry()
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.getSelectedCountry = this.getSelectedCountry.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.getLimits = this.getLimits.bind(this);
        this.refresh = this.refresh.bind(this);
        this.refresh();

        this.success_email_change = "E-mail byl úspěšně změněn."
        this.success_password_change = "Heslo bylo úspěšně změněno."
        this.success_contact_update = "Údaje byly aktualizovány."
        this.error_empty = "Vyplňte všechna pole.";
        this.error_password_change = "Heslo se nepodařilo změnit.";
        this.error_password_same_as_old = "Nové heslo je stejné jako to staré.";
        this.error_password_new_not_matches = "Nová hesla se neshodují.";
        this.error_email_change = "E-mail se nepodařilo změnit.";
        this.error_email_exists = "Tento e-mail již používá jiný uživatel.";
        this.error_wrong_password = "Současné heslo nesouhlasí.";
        this.error_contact_update = "Údaje se nepodařilo aktualizovat."
    }

    getSelectedCountry(){
        return this.countryOptions.find(item => item.value === this.props.auth.user.contact.country);
    }

    componentWillUnmount() {
        //window.serverlets_sidemenu_hide = false;
    }

    componentDidCache(){
        dropByCacheKey('Settings');
    }

    refresh(event = null){
        let page = this.state.params["page"];
        if(event){
            let params = this.state.params;
            params["page"] = event.target.name;
            this.setState({
                update : true,
                params : params
            });
            page = event.target.name;
        }
        if(page === "limits"){
            this.getLimits();
        }
        if(page === "devices"){
            this.loadDevices();
        }
    }

    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    handleCheckboxChange(event) {
        this.setState({
            [event.target.name] : (event.target.value === "true")
        });
    }

    getLimits(){
        Api.get(`/user/limits`)
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.setState({
                        limits : res.limits,
                        limitsLoaded : true
                    })
                }
            });
    }

    loadDevices(){
        Api.get("/user/sessions")
            .then(res => res.json())
            .then(data => {
                if(data["authenticated"]){
                    this.setState({
                        devices : Object.values(data["sessions"]),
                        devicesLoaded : true
                    });
                }
            });
    }

    logoutFromDevices(){
        Api.delete("/user/sessions")
            .then(res => res.json())
            .then(data => {
                if(data["authenticated"]){
                    this.loadDevices();
                }
            });
    }

    getItemClass(item_name, item_classname){
        return (this.state.params["page"] === item_name) ? item_classname + " active" : item_classname;
    }

    changeEmail(event){
        Api.put(`/user/email`,{
            email : this.state.newEmail,
            password : this.state.password
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.setState({
                        newEmail : "",
                        password : ""
                    })
                    this.props.auth.enter();
                    Api.show_message(this.success_email_change, "success");
                }else{
                    let mes = this.error_email_change;
                    if(res.error){
                        mes = (res.error.list[0] === "empty") ? this.error_empty : mes;
                        mes = (res.error.list[0] === "email-exists") ? this.error_email_exists : mes;
                        mes = (res.error.list[0] === "wrong-password") ? this.error_wrong_password : mes;
                    }
                    Api.show_message(mes, "error");
                }
            });
    }

    changePassword(event){
        Api.put(`/user/password`,{
            password : this.state.password,
            new_password : this.state.newPassword,
            new_password_again : this.state.newPasswordAgain
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.setState({
                        password : "",
                        newPassword : "",
                        newPasswordAgain : ""
                    })
                    Api.show_message(this.success_password_change, "success");
                }else{
                    let mes = this.error_password_change;
                    if(res.error){
                        mes = (res.error.list[0] === "empty") ? this.error_empty : mes;
                        mes = (res.error.list[0] === "same-password") ? this.error_password_same_as_old : mes;
                        mes = (res.error.list[0] === "passwords-not-match") ? this.error_password_new_not_matches : mes;
                        mes = (res.error.list[0] === "wrong-password") ? this.error_wrong_password : mes;
                    }
                    Api.show_message(mes, "error");
                }
            });
    }

    updateContact(event){
        Api.put(`/user/contact`,{
            personal: this.state.personal,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            company: this.state.company,
            street: this.state.street,
            street_no: this.state.streetNo,
            postal_code: this.state.postalCode,
            city: this.state.city,
            country: this.state.country,
            ic : this.state.ic,
            dic : this.state.dic,
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    Api.show_message(this.success_contact_update, "success");
                    this.props.auth.enter();
                }else{
                    Api.show_message(this.error_contact_update, "error");
                }
            });
    }

    handleCountryChange = (selectedOption) => {
        this.setState({
            selectedCountry : selectedOption,
            country : selectedOption.value
        });
    };

    render(){
        let limits = [];
        let devices = [];
        let changes = [];

        if(this.state.params.page === "devices" && this.state.devicesLoaded){
            let i = 0;
            this.state.devices.forEach(item => {
                let ui_parser = new UAParser(item["user-agent"]);
                let time = new Date(item["created"] * 1000);
                console.log(ui_parser.getDevice());
                devices.push(
                    <div className="device" data-device={(ui_parser.getDevice().type) ? ui_parser.getDevice().type : ""} key={i++}>
                        {
                            Boolean(item["this_device"]) && <span className="this_device">Toto zařízení</span>
                        }
                        <span className="os" data-os={ui_parser.getOS().name}>{ui_parser.getOS().name} {ui_parser.getOS().version}</span><br/>
                        <span className="browser" data-browser={ui_parser.getBrowser().name}>{ui_parser.getBrowser().name} {ui_parser.getBrowser().version}</span>
                        <span className="created">Přihlášen: &nbsp;{time.toLocaleDateString()} {time.toLocaleTimeString()}</span>
                        <span className="ip">IP adresa: &nbsp;{item["ip"]}</span>
                    </div>
                )
            });
        }

        if(this.state.params.page === "limits" && this.state.limitsLoaded){
            let i = 0;
            this.state.limits.forEach(item => {
                limits.push(
                    <div className="limit" key={i++}>
                        <span className="title">{item.title}</span>
                        <progress max={item.max} value={item.used}/>
                        <span className="label">{item.used} / {item.max}</span>
                    </div>
                )
            });
        }

        if(this.state.params.page === "information"){
            let i = 0;
            Api.client.changelog.forEach(item => {
                changes.push(
                    <div className="item" key={i++}>
                        <span className={`type ${item.type}`}>{item.type === "change" ? "Změna" : (item.type === "bugfix" ? "Oprava" : item.type)}</span>
                        <span className="mes">{item.mes}</span>
                    </div>
                )
            });
        }


        return (
            <Fragment>
                <Container>
                    <SideMenu className={(window.serverlets_sidemenu_hide) ? "sidemenu" : "sidemenu"} pathname={this.props.router.location.pathname}/>
                    <div className="serverlet_sidemenu settings_sidemenu">
                        <ul>
                            <li><Link onClick={this.refresh} to="/settings/email" name="email" className={this.getItemClass("email", "user-icon")}>E-mail</Link></li>
                            <li><Link onClick={this.refresh} to="/settings/password" name="password" className={(this.state.params["page"] === "password") ? "password-icon active" : "password-icon"}>Heslo</Link></li>
                            <li><Link onClick={this.refresh} to="/settings/contact" name="contact" className={(this.state.params["page"] === "contact") ? "contact-icon active" : "contact-icon"}>Fakturační údaje</Link></li>
                            <li><div className="delimiter"></div></li>
                            <li><Link onClick={this.refresh} to="/settings/limits" name="limits" className={(this.state.params["page"] === "limits") ? "limits-icon active" : "limits-icon"}>Limity</Link></li>
                            <li><Link onClick={this.refresh} to="/settings/devices" name="devices" className={(this.state.params["page"] === "devices") ? "devices-icon active" : "devices-icon"}>Aktivní zařízení</Link></li>
                            <li><Link onClick={this.refresh} to="/settings/information" name="information" className={(this.state.params["page"] === "information") ? "information-icon active" : "information-icon"}>Informace</Link></li>
                            <li><Link onClick={this.refresh} to="/settings/close-account" name="close-account" className={(this.state.params["page"] === "close-account") ? "close-account-icon active" : "close-account-icon"}>Smazání účtu</Link></li>
                        </ul>
                    </div>
                    <div className="serverlet_content settings_content" ref={this.pageRef}>
                        {
                            this.state.params["page"] === "email" &&
                            <div className="section">
                                <h1>Změna e-mailu</h1>
                                <span className="inner-blank">
                                    <input type="text" name="email" value={this.props.auth.user.email} autoComplete="off" disabled/>
                                    <input onChange={this.handleInputChange} type="text" name="newEmail" value={this.state.newEmail} placeholder="Nový e-mail" autoComplete="off" required/>
                                    <input onChange={this.handleInputChange} type="password" name="password" value={this.state.password} placeholder="Současné heslo" autoComplete="off" required/>
                                    <input onClick={this.changeEmail} type="submit" value="Změnit e-mail"/>
                                </span>
                            </div>
                        }
                        {
                            this.state.params["page"] === "password" &&
                            <div className="section">
                                <h1>Změna hesla</h1>
                                <span className="inner-blank">
                                    <input onChange={this.handleInputChange} type="password" name="newPassword" value={this.state.newPassword} placeholder="Nové heslo" autoComplete="off" required/>
                                    <input onChange={this.handleInputChange} type="password" name="newPasswordAgain" value={this.state.newPasswordAgain} placeholder="Nové heslo znovu" autoComplete="off" required/>
                                    <input onChange={this.handleInputChange} type="password" name="password" value={this.state.password} placeholder="Současné heslo" autoComplete="off" required/>
                                    <input onClick={this.changePassword} type="submit" value="Změnit heslo"/>
                                </span>
                            </div>
                        }
                        {
                            this.state.params["page"] === "contact" &&
                            <div className="section">
                                <h1>Změna fakturačních údajů</h1>
                                <span className="inner-blank">
                                    <span className="toggle-btn">
                                        <input id="toggle-on" onChange={this.handleCheckboxChange} className="toggle toggle-left" name="personal" value={true} type="radio" checked={this.state.personal}/>
                                        <label htmlFor="toggle-on" className="btn">Osoba</label>
                                        <input id="toggle-off" onChange={this.handleCheckboxChange} className="toggle toggle-right" name="personal" value={false} type="radio" checked={!this.state.personal}/>
                                        <label htmlFor="toggle-off" className="btn">Firma</label>
                                    </span>
                                    {
                                        this.state.personal &&
                                        <Fragment>
                                            <input value={this.state.firstname} onChange={this.handleInputChange} type="text" className="split2 split-space" name="firstname" autoComplete="off" placeholder="Jméno" required/>
                                            <input value={this.state.lastname} onChange={this.handleInputChange} type="text" className="split2" name="lastname" autoComplete="off" placeholder="Příjmení" required/>
                                        </Fragment>
                                    }
                                    {
                                        !this.state.personal &&
                                        <Fragment>
                                            <input value={this.state.company} onChange={this.handleInputChange} type="text" className="" name="company" autoComplete="off" placeholder="Společnost" required/>
                                        </Fragment>
                                    }
                                    <span className="delimiter"/>
                                    <input value={this.state.street} onChange={this.handleInputChange} type="text" className="split13 split-space" name="street" autoComplete="off" placeholder="Ulice" required/>
                                    <input value={this.state.streetNo} onChange={this.handleInputChange} type="text" className="split3" name="streetNo" autoComplete="off" placeholder="č.p / č.o." required/>

                                    <input value={this.state.postalCode} onChange={this.handleInputChange} type="text" className="split4 split-space" name="postalCode" autoComplete="off" placeholder="PSČ" maxLength="5" required/>
                                    <input value={this.state.city} onChange={this.handleInputChange} type="text" className="split14" name="city" autoComplete="off" placeholder="Obec" required/>

                                    <SelectList
                                        options={this.countryOptions}
                                        labelName="label"
                                        valueName="value"
                                        selectedOption={this.state.selectedCountry}
                                        onChange={this.handleCountryChange}
                                        noMessage="Stát nenalezen"
                                        placeholder="Stát"
                                    />

                                    {
                                        !this.state.personal &&
                                        <Fragment>
                                            <input value={this.state.ic} onChange={this.handleInputChange} type="text"  maxLength="8" className="split2 split-space" name="ic" autoComplete="off" placeholder="IČ" required/>
                                            <input value={this.state.dic} onChange={this.handleInputChange} type="text" maxLength="12" className="split2" name="dic" autoComplete="off" placeholder="DIČ" required/>
                                        </Fragment>
                                    }
                                    <input onClick={this.updateContact} type="submit" value="Uložit změny"/>
                                </span>
                            </div>
                        }
                        {
                            this.state.params["page"] === "limits" &&
                            <div className="section">
                                <h1>Limity účtu</h1>
                                <span className="inner-blank">
                                    {
                                        this.state.limitsLoaded &&
                                        <Fragment>
                                            <div className="list">
                                                {limits}
                                            </div>
                                            <p className="small">Pro zvýšení výchozích limitů nás prosím kontaktujte.</p>
                                        </Fragment>
                                    }
                                </span>
                            </div>
                        }
                        {
                            this.state.params["page"] === "devices" &&
                            <div className="section">
                                <h1>Aktivní zařízení</h1>
                                <span className="inner-blank">
                                    <div className="devices">{devices}</div>
                                    {
                                        this.state.devices.length > 1 &&
                                        <input onClick={this.logoutFromDevices} type="submit" value="Odhlásit z ostatních zařízení"/>
                                    }
                                </span>
                            </div>
                        }
                        {
                            this.state.params["page"] === "close-account" &&
                            <div className="section">
                                <h1>Smazání účtu</h1>
                                <span className="inner-blank">
                                    <p>Pro zrušení účtu je třeba mít doběhnuté věškeré předplacené služby a následně smazané z účtu. Každá žádost o zrušení je posuzována inviduálně našim pracovníkem a bude zpracována během několika dní.</p>
                                    <p>Nespotřebovaný kredit není možné vyplácet zpět na bankovní účet. Pokud zákazník požádá o zrušení účtu, kredit na daném účtu nenávratně propadá.</p>
                                    <p>S žádostí o zrušení účtu nás prosím kontaktujte e-mailem na adresu <a href="mailto:info@futrou.com?subject=Žádost o zrušení účtu&body=Dobrý den, chtěl/a bych požádat o zrušení účtu." target="_blank" rel="noreferrer" >info@futrou.com</a>.</p>
                                </span>
                            </div>
                        }
                        {
                            this.state.params["page"] === "information" &&
                            <div className="section">
                                <h1>Informace</h1>
                                <span className="inner-blank">
                                    <div className="info-box">
                                        <h2>{Api.client.name}</h2>
                                        Verze: {Api.client.version}<br/>
                                        Poslední aktualizace: {Api.client.build_date.toLocaleString()}
                                    </div>
                                    <div className="info-box">
                                        <h2>{this.props.auth.generator} API</h2>
                                        Verze: {this.props.auth.version}<br/>
                                    </div>
                                    <div className="info-box">
                                        <h2>Hlášení chyb</h2>
                                        <p>E-mail: {this.props.auth.bug_report}</p>
                                    </div>
                                    <div className="info-box">
                                        <h2>Historie změn</h2>
                                        <h3>verze {Api.client.version}</h3>
                                        <div className="changelog">
                                            {changes}
                                        </div>
                                    </div>
                                </span>
                            </div>
                        }
                    </div>
                </Container>
            </Fragment>
        );
    }
}
export default Settings;