import React from 'react';
import Select from "react-select";

class SelectList extends React.Component{
    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);

        this.options = [];
        this.selectedOption = null;
        if(this.props.options !== undefined && this.props.options !== null && this.props.options.length > 0){
            this.props.options.forEach( option => {
                let newOption = {
                    label : option[this.props.labelName],
                    value : option[this.props.valueName]
                };
                if(
                    this.props.selectedOption !== undefined &&
                    this.props.selectedOption !== null &&
                    this.props.selectedOption[this.props.valueName] === option[this.props.valueName]
                ){
                    this.selectedOption = newOption;
                }
                this.options.push(newOption);
            });
        }

        this.state = {
            options : this.options,
            selectedOption : this.selectedOption
        }

        this.customSelect = {
            control: base => ({
                ...base,
                height: 52,
                minHeight: 52
            }),
            valueContainer: provided => ({
                ...provided,
                height: 52,
                minHeight: 52
            }),
            input: base => ({
                position : "relative",
                top: -18
            }),
            option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#fff" : "#fff",
                color: "#202020",
                padding: "0.65em 1em",
                "&:hover": {
                    backgroundColor: "#fef2d1"
                }
            })
        };
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            (
                (
                    prevProps.selectedOption === null ||
                    prevProps.options === null ||
                    prevProps.options.length === 0
                ) &&
                this.props.selectedOption !== null &&
                this.props.options !== null &&
                this.props.options.length > 0
            ) ||
            (
                (
                    prevProps.options !== this.props.options
                ) &&
                this.props.selectedOption !== null &&
                this.props.options !== null &&
                this.props.options.length > 0
            )
        ){
            this.options = [];
            this.selectedOption = null;
            this.props.options.forEach( option => {
                let newOption = {
                    label : option[this.props.labelName],
                    value : option[this.props.valueName]
                };
                if(
                    this.props.selectedOption !== undefined &&
                    this.props.selectedOption !== null &&
                    this.props.selectedOption[this.props.valueName] === option[this.props.valueName]
                ){
                    this.selectedOption = newOption;
                }
                this.options.push(newOption);
            });

            this.setState({
                options : this.options,
                selectedOption : this.selectedOption
            });
        }
    }

    handleSelect(selectedOption){
        this.setState({
            selectedOption : selectedOption
        });
        this.props.options.forEach( option => {
            if(
                option[this.props.valueName] === selectedOption.value &&
                this.props.onChange !== undefined
            ){
                this.props.onChange(option);
            }
        });
    }

    render(){
        return (
            <div className="nic">
                <Select
                    value={this.state.selectedOption}
                    onChange={this.handleSelect}
                    options={this.state.options}
                    height="52px"
                    placeholder={this.props.placeholder}
                    className="select"
                    classNamePrefix="select"
                    noOptionsMessage={({inputValue}) => !inputValue ? inputValue : this.props.noMessage }
                    styles={this.customSelect}
                />
            </div>
        )
    }
}
export default SelectList;