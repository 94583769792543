import React, { Fragment } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { dropByCacheKey } from 'react-router-cache-route'
import './Login.css';
import './Signup.css';
import './Recovery.css';
import VersionInfo from "../../components/VersionInfo/VersionInfo";

class Recovery extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            verify_error : false,
            verify_error_message : "Zadaný kód není správný",
            submit_loader : false,
            logged: false,
            step: 1,
            email: "",
            password: "",
            passwordAgain: "",
            focused: [],
            code1 : "",
            code2 : "",
            code3 : "",
            code4 : "",
            code5 : "",
            code6 : "",
            code : ""
        }
        this.codes = [1,2,3,4,5,6]
        this.codesRefs = [];

        this.email = React.createRef();
        this.password = React.createRef();
        this.passwordAgain = React.createRef();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCodeSelect = this.handleCodeSelect.bind(this);
        this.handleKeyCode = this.handleKeyCode.bind(this);
        this.handleCodePaste = this.handleCodePaste.bind(this);
        props.router.cacheLifecycles.didCache(this.componentDidCache);

    }
    componentDidCache(){
        dropByCacheKey('Signup');
    }

    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
        if(
            event.target.name === "email"
        ){
            this.validateStep1();
        }
        if(
            event.target.name === "password" ||
            event.target.name === "passwordAgain"
        ){
            this.validateStep3();
        }
    }

    handleCheckboxChange(event) {
        this.setState({
            [event.target.name] : (event.target.value === "true")
        });
    }

    handleInputFocus(event) {
        let focused_new = this.state.focused;
        focused_new[event.target.name] = true;
        this.setState({
            focused : focused_new
        })
    }

    handleInputBlur(event) {
        if(event.target.value === ""){
            let focused_new = this.state.focused;
            focused_new[event.target.name] = false;
            this.setState({
                focused : focused_new
            })
        }
    }

    async handleSubmit(event) {
        if(event.target.name === "back"){
            this.setState({
                step : this.state.step-1
            });
        }else{
            this.setState({
                submit_loader : true
            });
            if(event.target.name === "submit1"){
                if(this.canSubmitStep1()){
                    this.recovery();
                }
            }
            if(event.target.name === "finish"){
                if(this.canSubmitStep3()){
                    this.finishRecovery();
                }
            }
            if(event.target.name === "startover"){
                await this.startOver();
            }
            if(event.target.name === "verifycode"){
                await this.verifyCode();
            }
        }
    }

    handleCodePaste(event){
        event.clipboardData.items[0].getAsString(text=> {
            var chars = text.split('');
            this.setState({
                code1 : (chars[0]) ?? "",
                code2 : (chars[1]) ?? "",
                code3 : (chars[2]) ?? "",
                code4 : (chars[3]) ?? "",
                code5 : (chars[4]) ?? "",
                code6 : (chars[5]) ?? ""
            });
            this.codesRefs[6].current.focus();
        })
    }

    handleCodeSelect(event){
        let id = parseInt(event.target.getAttribute("data-id"));
        let old = this.state[event.target.name];
        this.setState({
            [event.target.name] : event.target.value
        });
        if(!old && id < 6){
            this.codesRefs[id + 1].current.focus();
        }
    }

    handleKeyCode(e){
        let id = parseInt(e.target.getAttribute("data-id"));
        if(e.keyCode === 8){
                let currentNum = this.state[e.target.name];
                if(!currentNum && id > 1){
                    this.codesRefs[id - 1].current.focus();
                }
        }
    }

    async recovery(){
        let output = await this.props.auth.recovery(
            this.state.email
        );
        this.setState({
            step : (output.result) ? 2 : (output.response.blocked ? 6 : 5 ),
            submit_loader : false
        });
    }

    async startOver(){
        let output = await this.props.auth.resetRecoveryState();
        if(output.result){
            this.setState({
                step : 1,
                submit_loader : false,
                verify_error : false,
                logged: false,
                email: "",
                password: "",
                passwordAgain: "",
                focused: [],
                code1 : "",
                code2 : "",
                code3 : "",
                code4 : "",
                code5 : "",
                code6 : "",
                code : ""
            });
        }
    }

    async verifyCode(){
        let output = await this.props.auth.recoveryVerifyCode(this.getCode());
        if(output.result){
            this.setState({
                step : 3,
                submit_loader : false
            });
        }else{
            if(output.response.blocked){
                this.setState({
                    step : 6,
                    submit_loader : false
                });
            }else{
                this.setState({
                    verify_error : true,
                    submit_loader : false
                });
            }
        }
    }

    async finishRecovery(){
        let output = await this.props.auth.finishRecovery(
            this.getCode(),
            this.state.password
        );
        this.setState({
            step : (output.result) ? 4 : (output.response.blocked ? 5 : 6 ),
            submit_loader : false
        });
    }

    getCode(){
        return this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4 + this.state.code5 + this.state.code6;
    }

    validateStep1(){
        let error = false;
        if(this.email.current.value.length > 0){
            if(this.email.current.value.indexOf("@") === -1 || this.email.current.value.indexOf(".") === -1 || this.email.current.value.split(".").slice(-1)[0].length === 0){
                this.email.current.classList.add("input-error");
                error = true;
            }else{
                this.email.current.classList.remove("input-error")
            }
        }else{
            this.email.current.classList.remove("input-error")
        }
        return error;
    }

    validateStep3(){
        let error = false;
        if(this.password.current.value.length > 0){
            if(this.password.current.value.length < 8){
                this.password.current.classList.add("input-error");
                error = true;
            }else{
                this.password.current.classList.remove("input-error")
            }
        }else{
            this.password.current.classList.remove("input-error")
        }
        if(this.passwordAgain.current.value.length > 0){
            if(this.passwordAgain.current.value !== this.password.current.value){
                this.passwordAgain.current.classList.add("input-error");
                error = true;
            }else{
                this.passwordAgain.current.classList.remove("input-error")
            }
        }
        return error;
    }

    canSubmitStep1(){
        if(
            this.email.current == null ||
            this.email.current.value.length === 0
        ){
            return false;
        }
        return (!this.validateStep1());
    }

    canSubmitStep3(){
        if(
            this.password.current == null ||
            this.passwordAgain.current == null ||
            this.password.current.value.length === 0 ||
            this.passwordAgain.current.value.length === 0
        ){
            return false;
        }
        return (!this.validateStep3());
    }

    render(){
        let codes = [];
        if(this.state.step === 2){
            this.codes.forEach(item =>{
                this.codesRefs[item] = React.createRef();
                codes.push(
                    <input type="text" onPaste={this.handleCodePaste} data-id={item} onKeyDown={this.handleKeyCode} ref={this.codesRefs[item]} name={"code" + item} maxLength="1" value={this.state["code" + item]} onChange={this.handleCodeSelect} placeholder="#"/>
                )
            })
        }
        if(this.props.auth.authenticated){
            return <Redirect to="/"/>;
        }else{
            return(
                <Fragment>
                    <div className="login-container">
                        <div className="form-page">
                            <div className="form-box">
                                {
                                    (this.state.step >= 1 && this.state.step <= 3) &&
                                    <span className="step">krok {this.state.step} / 3</span>
                                }
                                {
                                    (this.state.step >= 1 && this.state.step <= 3) &&
                                    <h2>Obnovení hesla</h2>
                                }
                                {
                                    this.state.step === 4 &&
                                    <Fragment>
                                        <span className="top-success"/>
                                        <h2>Obnovení dokončeno</h2>
                                    </Fragment>                            }
                                {
                                    this.state.step === 5 &&
                                    <Fragment>
                                        <span className="top-error"/>
                                        <h2>Během obnovení hesla došlo k chybě</h2>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 6 &&
                                    <Fragment>
                                        <span className="top-error"/>
                                        <h2>Obnovení hesla zablokováno</h2>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 1 &&
                                        <Fragment>
                                            <h3>Zadejte prosím e-mail požadovaného účtu</h3>
                                            <input id="email" value={this.state.email} onChange={this.handleInputChange} type="text" className="email-icon" placeholder="E-mail" name="email" autoComplete="off" ref={this.email} required/>
                                            <input type="submit" name="submit1" value="Pokračovat" className={(this.canSubmitStep1()) ? "next-icon" : "next-icon input-disabled"} onClick={this.handleSubmit}/>
                                        </Fragment>
                                }
                                {
                                    this.state.step === 2 &&
                                    <Fragment>
                                        <div className="email-verification">
                                            Zadejte prosím ověřovací kód, který jsme Vám právě zaslali na e-mail.
                                            {(this.state.verify_error) ? <div className="error">{this.state.verify_error_message}</div> : <Fragment/> }
                                            <div className="code-input">
                                                {codes}
                                            </div>
                                            <div className="bottom_container">
                                                <input type="submit" name="startover" className="reset prev-icon" value="Začít znovu" onClick={this.handleSubmit}/>
                                                <input type="submit" name="verifycode" className={ this.state.submit_loader ? "finish next-loader" : "finish next-icon"} value="Ověřit" onClick={this.handleSubmit}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 3 &&
                                    <Fragment>
                                            <h3>Už zbývá jen krůček. Zadejte prosím své nové heslo.</h3>
                                            <input value={this.state.password} onChange={this.handleInputChange} type="password" className="password-icon" name="password" autoComplete="off" placeholder="Heslo" ref={this.password} required/>
                                            <input value={this.state.passwordAgain} onChange={this.handleInputChange} type="password" className="password-icon" name="passwordAgain" autoComplete="off" placeholder="Heslo znovu" ref={this.passwordAgain} required/>
                                            <input type="submit" name="finish" value="Změnit heslo" className={(this.canSubmitStep3()) ? "next-icon" : "next-icon input-disabled"} onClick={this.handleSubmit}/>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 4 &&
                                    <Fragment>
                                        <div className="email-verification">
                                            <p>A je to! Vaše heslo bylo úspěšně změněno. Nyní se již opět můžete přihlásit.</p>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 5 &&
                                    <Fragment>
                                        <div className="email-verification">
                                            <p>Během procesu obnovení hesla došlo k chybě. Zkuste to prosím znovu později. Pokud problém přetrvává, kontaktujte nás.</p>
                                            <div className="bottom_container">
                                                <input type="submit" name="submit6" className="prev-icon" value="Začít znovu" onClick={this.handleSubmit}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 6 &&
                                    <Fragment>
                                        <div className="email-verification">
                                            <p>Vaše zařízení bylo dočasně zablokováno, z důvodu příliš mnoha provedených pokusů. Zkuste to prosím znovu za několik minut.</p>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                            <div className="bottom">
                                <Link to="/login">Zpět k přihlášení</Link>
                            </div>
                        </div>
                    </div>
                    <VersionInfo/>
                </Fragment>
            )
        }
    }
}
export default Recovery;