import React from 'react';
import './Content.css';

const Content = (props) => {
    return (
        <div className={props.className ? props.className + " content" : "content"}>
            {props.children}
        </div>
    );
};
export default Content;