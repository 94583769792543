import React, { Fragment } from 'react';
import {dropByCacheKey} from "react-router-cache-route";
import "./Serverlets.css"
import Api from "../ApiLib"

class ServerletSoftware extends React.Component{
    constructor(props){
        super(props);
        this.reload = this.reload.bind(this);
        this.state = {};
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSoftwareAction = this.handleSoftwareAction.bind(this);
    }

    reload(){
        dropByCacheKey('Serverlet');
    }

    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }


    softwareIconExists(name){
        let software_icons = [
            "lsws",
            "litespeed",
            "openlitespeed",
            "nginx",
            "apache",
            "lighttpd",
            "dropbear",

            "mariadb",
            "mongodb",
            "mysql",
            "postgresql",
            "mssql",
            "scylladb.svg",
            "percona",
            "oracledb",
            "influxdb",
            "couchdb",
            "couchbase",
            "clickhouse",
            "cassandra",
            "arangodb",
            "immudb",
            "questdb",

            "redis",
            "memcached",
            "keydb",

            "elasticsearch",
            "prometheus",
            "grafana",
            "django",
            "strapi",
            "airbyte",
            "appsmith",
            "appwrite",
            "budibase",
            "bugfender",
            "chatwood",
            "cloudcasa",
            "cloudron",
            "clustercontrol",
            "discourse",
            "flipstarter",
            "ghost",
            "gitea",
            "gitlab",
            "invoiceninja",
            "ispmanager",
            "jaeger",
            "linkerd",
            "magento",
            "mastodon",
            "meilisearch",
            "microweber",
            "nakama",
            "nextcloud",
            "nirvashare",
            "onlyoffice",
            "opendax",
            "opensearch",
            "photoprism",
            "redash",
            "repman",
            "rocketchat",
            "seafile",
            "solidinvoice",
            "supabase",
            "wordpress",
            "zabbix",
            "zulip",
        ];
        return software_icons.includes(name);
    }


    handleSoftwareAction(event){
        event.target.classList.add("loading-button");
        let action = event.target.getAttribute("data-action");
        let name = event.target.getAttribute("data-name");
        Api.post(`/serverlet/${this.props.parent.state.params.id}/software/action`,{
            action : action,
            name : name
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    Api.show_message("Akce provedena", "success");
                    this.props.parent.getSoftware(event);
                }else{
                    Api.show_message("Akce se nezdařila", "error");
                }
            });
    }

    render(){
        let software = [];
        if(this.props.software.length > 0 && this.props.serverlet !== null){
            let i = 0;
            this.props.software.forEach(item => {
                let state = (item.state) ? "Spuštěno" : "Zastaveno";
                let enabled = (item.enabled) ? "Povoleno" : "Zakázáno";
                let has_icon = this.softwareIconExists(item.name);
                software.push(
                    <Fragment key={i++}>
                        <tr key={i++}>
                            <td className="icon">
                                <span><img alt="Software icon" src={(has_icon) ? "/img/software/" + item.name + ".svg" : "/img/software/generic.svg"}/></span>
                            </td>
                            <td>{item.name}</td>
                            {
                                Boolean(item.enabled) &&
                                <td className={(item.state) ? "state-running" : "state-stopped"}><span>{state}</span></td>
                            }
                            {
                                !Boolean(item.enabled) &&
                                <td className="state-disabled"><span>{enabled}</span></td>
                            }
                            <td className="right">
                                {
                                    Boolean(item.enabled) &&
                                    <Fragment>
                                        {
                                            Boolean(item.state) &&
                                            <button onClick={this.handleSoftwareAction} data-action="reload" data-name={item.name} className="action3-button reload-button"/>
                                        }
                                        {
                                            Boolean(item.state) &&
                                            <button onClick={this.handleSoftwareAction} data-action="restart" data-name={item.name} className="action2-button restart-button"/>
                                        }
                                        <button onClick={this.handleSoftwareAction} data-action={(item.state)? "stop" : "start"} data-name={item.name} className={(item.state) ? "action-button stop-button" : "action-button start-button"}/>
                                        <button onClick={this.handleSoftwareAction} data-action="disable" data-name={item.name} className="enable-button">Zakázat</button>
                                    </Fragment>
                                }
                                {
                                    !Boolean(item.enabled) &&
                                    <Fragment>
                                        {
                                            <button onClick={this.handleSoftwareAction} data-action="enable" data-name={item.name} className="enable-button">Povolit</button>
                                        }
                                    </Fragment>
                                }
                            </td>
                        </tr>
                    </Fragment>
                );
            });
        }
        return (
            <Fragment>
                <div className="section">
                    <h1 className="padded">Software</h1>
                    <span className="count">{this.props.software.length}</span>
                    {
                        (this.props.serverlet && this.props.serverlet.state === 1) &&
                        <Fragment>
                            {
                                !this.props.softwareLoaded &&
                                <div className="inner-none"><span>Připojuji se k serverletu...</span></div>
                            }
                            {
                                this.props.softwareLoaded &&
                                <Fragment>
                                    {
                                        this.props.software.length > 0 &&
                                        <div className="inner-frame software-list">
                                            <div className="list">
                                                <table className="table w-full">
                                                    <thead>
                                                    <tr>
                                                        <td/>
                                                        <td>Název</td>
                                                        <td>Stav</td>
                                                        <td/>
                                                    </tr>
                                                    </thead>
                                                    <tbody>{software}</tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.props.software.length === 0 &&
                                        <div className="inner-none"><span>Žádné programy</span></div>
                                    }
                                </Fragment>
                            }
                        </Fragment>
                    }
                    {
                        (this.props.serverlet && this.props.serverlet.state === 0) &&
                        <div className="full-warning">Pro správu programů musí být serverlet zapnutý.</div>
                    }
                </div>
            </Fragment>
        );
    }
}
export default ServerletSoftware;