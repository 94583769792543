import React, { Fragment } from 'react';
import Footer from '../components/Footer/Footer'
import './Error.css';

export const Error404 = (props) => {
    return (
        <Fragment>
            <div className="error-page">
                <h1>404</h1>
                <h2>Stránka nenalezena</h2>
                <p>Omlouváme se, ale tuto stránku se nám nepodařilo najít.</p>
            </div>
            <Footer/>
        </Fragment>
    );
}
export default Error404;