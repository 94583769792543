import React, { Fragment } from 'react';
import {dropByCacheKey} from "react-router-cache-route";
import "./Serverlets.css"

class ServerletDatabases extends React.Component{
    constructor(props){
        super(props);
        this.reload = this.reload.bind(this);
        this.state = {};
    }

    reload(){
        dropByCacheKey('Serverlet');
    }

    componentDidMount() {

    }

    render(){
        return (
            <Fragment>

            </Fragment>
        );
    }
}
export default ServerletDatabases;