import React, { Fragment } from 'react'
import './Footer.css'

function Footer(){
    return(
        <Fragment>
            <footer>
                <div className="out-content">
                    <div className="inner-content">
                    <nav>
                        <a href="/about/contact">Podpora</a>
                        <a href="/about/support">Nápověda</a>
                        <a href="/about/faq">Smluvní podmínky</a>
                        <a href="/about/media">Ochrana osobních údajů</a>
                    </nav>
                    </div>
                </div>
                <div className="out-content">
                    <div className="inner-content">
                        <span>© {(new Date().getFullYear())} futrou</span>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
}
export default Footer;