import React, {Fragment} from 'react';
import './PageMessage.css';

class PageMessage extends React.Component{
    constructor(props) {
        super(props);
        this.handleMessage = this.handleMessage.bind(this);
        document.addEventListener('page_message', this.handleMessage, true);
        this.state = {
            show : false,
            message : null,
            message_type : null,
            show_count : 0
        }
        this.timeout = null;
    }

    handleMessage(event){
        if(this.timeout){
            this.setState({
                show : false
            })
            clearTimeout(this.timeout);
            this.timeout = null;
            setTimeout((event) => {
                this.handleMessage(event);
            },1, event);
        }else{
            if(event.detail.message){
                this.setState({
                    show : true,
                    message : event.detail.message,
                    message_type : event.detail.message_type
                })
            }
            this.timeout = setTimeout(() =>{
                this.setState({
                    show : false
                })
                this.timeout = null;
            }, 6500);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('page_message', this.handleMessage, true);
    }

    componentDidMount(){
    }


    render() {
        if(this.state.show){
            let message_type_class = "hide_message";
            message_type_class = (this.state.message_type === "success") ? "success_message" : message_type_class;
            message_type_class = (this.state.message_type === "error") ? "error_message" : message_type_class;
            message_type_class = (this.state.message_type === "info") ? "info_message" : message_type_class;
            message_type_class = (this.state.message_type === "warning") ? "warning_message" : message_type_class;
            return(
                <div className={"page_message " + message_type_class}>
                    <span>{this.state.message}</span>
                </div>
            )
        }
        return (
            <Fragment/>
        )
    }
}
export default PageMessage;