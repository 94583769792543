import React, { Fragment } from 'react';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import {dropByCacheKey} from "react-router-cache-route";
import {Link} from "react-router-dom";
import Api from "../ApiLib";

class Main extends React.Component{
    constructor(props){
        super(props);
        this.carouselRef =React.createRef();
        props.router.cacheLifecycles.didCache(this.componentDidCache);
        this.scroll = this.scroll.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.state = {
            update : true,
            carouselStart : true,
            carouselEnd : false
        };
    }
    scroll = (scrollOffset) => {
        let new_left = this.carouselRef.current.scrollLeft += scrollOffset;
        this.carouselRef.current.scrollLeft += scrollOffset;
        this.setState({
            carouselStart : new_left <= 0,
            carouselEnd : new_left >= this.carouselRef.current.scrollLeftMax
        })
    };

    componentDidMount() {
        this.carouselRef.current.scrollLeft = 0;
    }

    componentDidCache(){
        dropByCacheKey('Main');
    }
    render(){
        let changes = [];

        let i = 0;
        Api.client.changelog.forEach(item => {
            changes.push(
                <div className="item" key={i++}>
                    <span className={`type ${item.type}`}>{item.type === "change" ? "Změna" : (item.type === "bugfix" ? "Oprava" : item.type)}</span>
                    <span className="mes">{item.mes}</span>
                </div>
            )
        });

        let serverlets = [];
        let serverlet_plans = this.props.auth.get_serverlet_plans();
        if(serverlet_plans.length > 0){
            serverlet_plans.forEach(item => {
                serverlets.push(
                    <Link key={item.id} to={"/serverlets/create/" + item.id} className="hbox-link">
                        <div data-id={item.id} onClick={this.selectPlan} className="box">
                            <span className="title">{item.name}</span>
                            <span className="params">{item.cpu}CPU {item.ram}MB {item.storage/1000}GB</span>
                            <span className="price">{item.monthly_price} Kč/m</span>
                        </div>
                    </Link>
                );
            });
        }else{
            for(let i = 0; i < 6; i++){
                serverlets.push(<div key={i} className="box"/>);
            }
        }
        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <Content>
                        <div className="credit-banner mobile-only">
                            <div className="title">Kredit</div>
                            <div className="value">{this.props.auth.user.credit} <i>Kč</i></div>
                            <Link to="/billing/orders/create" className="add">Navýšit</Link>
                        </div>
                        <div className="banner">
                            <h2>Vítejte v administraci</h2>
                            <h3>Zde můžete spravovat své služby</h3>
                            <a href="https://help.futrou.com" className="button-black" rel="noreferrer" target="_blank">Nápověda</a>
                            <img src="/img/banner2.svg" alt="Banner"/>
                        </div>
                        <div className="section">
                            <h1>Nový serverlet</h1>
                            <span className="inner-frame">
                                <div className="carousel" ref={this.carouselRef}>
                                {serverlets}
                                <button className={this.state.carouselStart ? "arrow prev hide" : "arrow prev" } onClick={() => this.scroll(-142)}>❮</button>
                                <button className={this.state.carouselEnd ? "arrow next hide" : "arrow next" } onClick={() => this.scroll(142)}>❯</button>
                                </div>
                            </span>
                        </div>
                        <div className="section-half monthly-graph">
                            <h1>Měsíční výdaje</h1>
                            <span className="inner">
                                <div className={(this.props.auth.user.monthly_costs > this.props.auth.user.credit) ? "monthly monthly-red" : "monthly monthly-green"}>{this.props.auth.user.monthly_costs} Kč</div>
                                <div className="monthly-status">{(this.props.auth.user.monthly_costs > this.props.auth.user.credit) ? "Nedostatek":"Dostatek"} kreditu pro další období</div>
                            </span>
                        </div>
                        <div className="section-half">
                            <h1>Přehled novinek</h1>
                            <span className="inner changelog-scrollable">
                                <div className="changelog">
                                    {changes}
                                </div>
                            </span>
                        </div>
                    </Content>
                </Container>
            </Fragment>
        )
    }
}
export default Main;