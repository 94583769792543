import React, { Fragment } from 'react';
import {dropByCacheKey} from "react-router-cache-route";
import "./Serverlets.css"
import Api from "../ApiLib"

class ServerletUsage extends React.Component{
    constructor(props){
        super(props);
        this.reload = this.reload.bind(this);
        this.state = {};
        this.handleCopy = this.handleCopy.bind(this);
    }


    handleCopy(event){
        navigator.clipboard.writeText(
            event.target.getAttribute("data-text")
        );
        Api.show_message("Zkopírováno", "info");
    }

    reload(){
        dropByCacheKey('Serverlet');
    }

    render(){
        let usedCpuPerc = 0;
        if(this.props.stats && this.props.bothLoaded){
            usedCpuPerc = (this.props.stats?.cpu_perc / this.props.serverlet?.plan.cpu)
            usedCpuPerc = usedCpuPerc > 100 ? 100 : usedCpuPerc;
            usedCpuPerc = usedCpuPerc.toFixed(1);
        }
        return (
            <Fragment>
                <div className="section">
                    <h1>Využití</h1>
                    <div className="inner-frame">
                        <table>
                            <tbody>
                            <tr>
                                <td>CPU</td>
                                {
                                    this.props.stats &&
                                    <td>{ usedCpuPerc }%</td>
                                }
                                {
                                    !this.props.stats &&
                                    <td>...</td>
                                }
                            </tr>
                            <tr>
                                <td>RAM</td>
                                {
                                    this.props.stats &&
                                    <td>{ this.props.stats?.ram_used ?? "..." } / { this.props.serverlet?.plan.ram ?? "..." }MiB</td>
                                }
                                {
                                    !this.props.stats &&
                                    <td>...</td>
                                }
                            </tr>
                            <tr>
                                <td>Uložiště</td>
                                {
                                    this.props.bothLoaded &&
                                    <td>{(this.props.storage.size < 1000) ? this.props.storage.size + "MB" : (this.props.storage.size/1000) + "GB" } / {this.props.serverlet.plan.storage/1000}GB</td>
                                }
                                {
                                    !this.props.bothLoaded &&
                                    <td>...</td>
                                }
                            </tr>
                            <tr>
                                <td>Síť - Staženo</td>
                                {
                                    this.props.stats &&
                                    <td>{ this.props.stats?.traffic_down ?? "..."}</td>
                                }
                                {
                                    !this.props.stats &&
                                    <td>...</td>
                                }
                            </tr>
                            <tr>
                                <td>Síť - Nahráno</td>
                                {
                                    this.props.stats &&
                                    <td>{ this.props.stats?.traffic_up ?? "..."}</td>
                                }
                                {
                                    !this.props.stats &&
                                    <td>...</td>
                                }
                            </tr>
                            <tr>
                                <td>Disk - Přečteno</td>
                                {
                                    this.props.stats &&
                                    <td>{ this.props.stats?.io_read ?? "..."}</td>
                                }
                                {
                                    !this.props.stats &&
                                    <td>...</td>
                                }
                            </tr>
                            <tr>
                                <td>Disk - Zapsáno</td>
                                {
                                    this.props.stats &&
                                    <td>{ this.props.stats?.io_write ?? "..."}</td>
                                }
                                {
                                    !this.props.stats &&
                                    <td>...</td>
                                }
                            </tr>
                            <tr>
                                <td>Připojených domén</td>
                                <td>{ this.props.domains.length } / 101</td>
                            </tr>
                            <tr>
                                <td>SSH účtů</td>
                                <td>{ this.props.users.length } / 101</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default ServerletUsage;