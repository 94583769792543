import React, {Fragment} from 'react';
import HorizontalMenu from "../HorizontalMenu/HorizontalMenu";

class DomainsMenu extends React.Component{
    render(){
        let items = [
            {
                to : "/domains/registrar",
                className : "domains-icon",
                title : "Mé domény"
            },
            {
                to : "/domains/dns",
                className : "domains-dns-icon",
                title : "DNS zóny"
            }
        ];
        return (
            <Fragment>
                <HorizontalMenu items={items} pathname={this.props.pathname}/>
            </Fragment>
        )
    }
}
export default DomainsMenu;