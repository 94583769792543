import React from 'react';
import './Header.css';
import Logo from './Logo.js';
import { Link } from 'react-router-dom';


class Header extends React.Component{
    constructor(props) {
        super(props);
        this.menuRef = React.createRef();
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);

        let parent = this;
        if(props.auth.user === null){
            this.state = {
                loaded : false,
                menu : false
            }
            props.auth.enter().then(res => {
                parent.setState({
                    loaded : true
                });
            });
        }else{
            this.state = {
                loaded : true
            }
        }
    }

    getUserTitle(){
        if(this.props.auth.user.contact.personal){
            return (this.props.auth.user.contact.firstname !== "") ?
                this.props.auth.user.contact.firstname + " " + this.props.auth.user.contact.lastname :
                this.props.auth.user.email;
        }else{
            return (this.props.auth.user.contact.company !== "") ?
                this.props.auth.user.contact.company :
                this.props.auth.user.email;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, true);
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside, true);
    }

    handleMenuOpen(){
        document.dispatchEvent(new Event("menu-open"));
    }

    handleMenuClick(event){
        this.setState({
            menu : !this.state.menu
        })
    }


    handleClickOutside(event) {
        if(this.menuRef.current !== null){
            if (this.menuRef && !this.menuRef.current.contains(event.target)) {
                if(this.state.menu){
                    this.setState({
                        menu : false
                    })
                }
            }
        }
    }


    creditToString(credit){
        credit = Math.round(credit * 100) / 100;
        return credit.toString().replace('.',',');
    }

    render() {
        return (
        <header>
            <Logo/>
            <div className="menu">
                <div className="credit desktop-only">
                    <div className="title">Kredit</div>
                    <div className="value">{(this.state.loaded) ? this.creditToString(this.props.auth.user.credit) : "..." } <i>Kč</i></div>
                    <Link to="/billing/orders/create" className="add">Navýšit</Link>
                </div>
                <div className="right">
                        {
                            (this.state.loaded) &&
                                <div className="panel desktop-only" ref={this.menuRef}>
                                    <div className={this.state.menu ? "user user-focused" : "user"} onClick={this.handleMenuClick}>
                                        <div className="avatar">{ this.getUserTitle()[0] }</div>
                                        <span>{ this.getUserTitle() }</span>
                                    </div>
                                    <div className={this.state.menu ? "menu show" : "menu hide"}>
                                        <ul>
                                            <li><Link onClick={this.handleMenuClick} to="/settings/email" className="settings">Nastavení</Link></li>
                                            <li><Link onClick={this.handleMenuClick} to="/logout" className="logout">Odhlásit se</Link></li>
                                        </ul>
                                    </div>
                                </div>
                        }
                        <div className="menu-open mobile-only">
                            <button onClick={this.handleMenuOpen}>Menu</button>
                        </div>
                </div>
            </div>
        </header>
    );
}
}
export default Header;