import React, { Fragment } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { dropByCacheKey } from 'react-router-cache-route'
import './Login.css';
import VersionInfo from "../../components/VersionInfo/VersionInfo";

class Login extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        logged : false,
        email : "",
        password : "",
        error : false,
        blocked : false,
        error_message : "Nesprávný e-mail nebo heslo",
        blocked_message : "Vaše zařízení bylo zablokováno, z důvodu příliš mnoha neúspěšných pokusů. Počkejte několik vteřin.",
        logged_out : props.auth.logged_out,
        logout_message : "Byli jste odhlášeni",
        submit_loader : false
      }
      props.auth.logged_out = false;
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputEnter = this.handleInputEnter.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      props.router.cacheLifecycles.didCache(this.componentDidCache);
    }
    componentDidCache(){
      dropByCacheKey('Login');
    }
    handleInputChange(event) {
      this.setState({[event.target.name] : event.target.value});
    }
    async handleSubmit() {
        this.setState({
            submit_loader : true,
            logged_out : false,
            error : false,
            blocked : false
        });
        let output = await this.props.auth.verify(this.state.email, this.state.password);
        let parent = this;
        if(output.result) {
            this.props.auth.enter().then(res => {
                parent.props.auth.update();
            });
        }else{
            setTimeout(function(){
                parent.setState({
                    submit_loader : false,
                    error : !output.response["login_result"],
                    blocked : output.response["blocked"],
                });
            }, 500);
        }
    }
    handleInputEnter(event){
        if(event.key === 'Enter') {
            this.handleSubmit();
        }
    }
    render(){
        if(this.state.logged || this.props.auth.authenticated){
            return <Redirect to="/"/>;
        }else{
            return(
                <Fragment>
                    <div className="login-container">
                        <div className="login-page">
                            <div className="login-left">
                                <div id="login">
                                    <h2>Prosím, přihlaste se<br/>ke svému účtu</h2>
                                    {(this.state.logged_out) ? <div className="info">{this.state.logout_message}</div> : <Fragment/> }
                                    {(this.state.error && !this.state.blocked) ? <div className="error">{this.state.error_message}</div> : <Fragment/> }
                                    {(this.state.blocked) ? <div className="error">{this.state.blocked_message}</div> : <Fragment/> }
                                    <input value={this.state.email} onChange={this.handleInputChange} type="text" name="email" autoComplete="off" placeholder="E-mail" required/>
                                    <input value={this.state.password} onKeyDown={this.handleInputEnter} onChange={this.handleInputChange}  type="password" name="password" autoComplete="off" placeholder="Heslo" required/>
                                    <input type="submit" name="login" value="Přihlásit se" onClick={this.handleSubmit} className={ this.state.submit_loader ? "submit-loader" : ""}/>
                                </div>
                                <div className="bottom">
                                    <Link to="/recovery">Zapomněli jste heslo?</Link>
                                </div>
                            </div>
                            <div className="login-right">
                                <h2>Objevte webhosting nové generace</h2>
                                <h3>S našemi Serverlety jste skutečným pánem nad svým webem</h3>
                                <Link to="/signup" className="login-button login-button-now">Vytvořit účet</Link>
                            </div>
                        </div>
                    </div>
                    <VersionInfo/>
                </Fragment>
            )
        }
    }
}
export default Login;