import React, {Fragment} from 'react';
import Main from './pages/Main';
import Serverlets from './pages/Serverlets';
import Serverlet from './pages/Serverlet';

import Error404 from './pages/Error404';
import Error500 from './pages/Error500';

import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import ScrollMemory from 'react-router-scroll-memory';
import {BrowserRouter, Redirect} from 'react-router-dom';
import Header from './components/Header/Header';
import PageMessage from './components/PageMessage/PageMessage'
import PageAlert from './components/PageAlert/PageAlert'

import Login from './pages/Accounts/Login';
import Logout from './pages/Accounts/Logout';
import Signup from './pages/Accounts/Signup';
import Recovery from './pages/Accounts/Recovery';

import Settings from './pages/Settings';

import Domains from "./pages/Domains";
import DomainsDNS from "./pages/DomainsDNS";
import DomainDNS from "./pages/DomainDNS";

import Auth from "./AuthLib";
import ServerletCreate from "./pages/ServerletCreate";
import CreditOrder from "./pages/CreditOrder";
import Orders from "./pages/Orders";
import CreditHistory from "./pages/CreditHistory";
import Support from "./pages/Support";
import Chat from "./components/Chat/Chat";

class App extends React.Component{
    constructor() {
        super();
        this.stateUpdate = this.stateUpdate.bind(this);
        this.state = {
            auth : new Auth(this.stateUpdate),
            update : true
        };
        this.state.auth.serverlet_plans();
    }

    stateUpdate(){
        this.setState({
            update: true
        });
    }

    render() {
        if(this.state.auth.loaded){
            return(
                <div className="App">
                    <Chat auth={this.state.auth}/>
                 <BrowserRouter>
                  <ScrollMemory />
                     {
                         this.state.auth.authenticated &&
                         <Fragment>
                             <PageAlert/>
                             <PageMessage/>
                             <Header auth={this.state.auth}/>
                         </Fragment>
                     }
                    <CacheSwitch>
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/" render = {routerProps => <Main router={routerProps} auth={this.state.auth}/> }/>
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/serverlets" cacheKey="Serverlets" render = {routerProps => <Serverlets router={routerProps} auth={this.state.auth} /> }/>
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/serverlets/create/:plan_id?" render = {routerProps => <ServerletCreate router={routerProps} auth={this.state.auth}  cacheKey="ServerletCreate"/> }/>
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/serverlet/:id/:page?" cacheKey="Serverlet" render = {routerProps => <Serverlet router={routerProps} auth={this.state.auth} /> }/>
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/domains/registrar" render = {routerProps => <Domains router={routerProps} auth={this.state.auth} cacheKey="Domains"/> } />
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/domains/dns" cacheKey="DomainsDNS" render = {routerProps => <DomainsDNS router={routerProps} auth={this.state.auth}/> } />
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/domain/dns/:domain" cacheKey="DomainDNS" render = {routerProps => <DomainDNS router={routerProps} auth={this.state.auth} /> } />
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/settings/:page?" cacheKey="Settings" render = {routerProps => <Settings router={routerProps} auth={this.state.auth}/> }/>
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/logout" render = {routerProps => <Logout router={routerProps} auth={this.state.auth}  cacheKey="Logout"/> }/>
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/billing/orders/create" cacheKey="CreditOrder" render = {routerProps => <CreditOrder router={routerProps} auth={this.state.auth} /> }/>
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/billing/orders" cacheKey="Orders" render = {routerProps => <Orders router={routerProps} auth={this.state.auth}/> }/>
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/billing/credit-history" cacheKey="CreditHistory" render = {routerProps => <CreditHistory router={routerProps} auth={this.state.auth}/> }/>
                        }
                        {
                            this.state.auth.authenticated &&
                            <CacheRoute exact path="/support" cacheKey="Support" render = {routerProps => <Support router={routerProps} auth={this.state.auth} /> }/>
                        }
                        <CacheRoute exact path="/login" render = {routerProps => <Login router={routerProps} auth={this.state.auth}/> } cacheKey="Login"/>
                        <CacheRoute exact path="/signup" render = {routerProps => <Signup router={routerProps} auth={this.state.auth}/> } cacheKey="Signup"/>
                        <CacheRoute exact path="/recovery" render = {routerProps => <Recovery router={routerProps} auth={this.state.auth}/> } cacheKey="Recovery"/>

                        {
                            !this.state.auth.authenticated &&
                            <Redirect to="/login"/>
                        }
                        <CacheRoute exact path="/500" component={Error500} />
                        <CacheRoute path="/*" component={Error404} />
                    </CacheSwitch>
                  </BrowserRouter>
                </div>
            )
        }else{
            return(
                <div className="App">
                    <div className="splash"></div>
                </div>
            )
        }
    }
}
export default App;