import React, { Fragment } from 'react';
import {dropByCacheKey} from "react-router-cache-route";
import "./Serverlets.css"
import Api from "../ApiLib"

class ServerletDashboard extends React.Component{
    constructor(props){
        super(props);
        this.reload = this.reload.bind(this);
        this.state = {};
        this.handleCopy = this.handleCopy.bind(this);
        this.getTillDeleteDaysString = this.getTillDeleteDaysString.bind(this);
        this.getSshCommand = this.getSshCommand.bind(this);
        this.getPaidTillString = this.getPaidTillString.bind(this);
    }


    handleCopy(event){
        navigator.clipboard.writeText(
            event.target.getAttribute("data-text")
        );
        Api.show_message("Zkopírováno", "info");
    }

    reload(){
        dropByCacheKey('Serverlet');
    }

    componentDidMount() {

    }

    getSshCommand(){
        return "ssh applet@" + this.props.serverlet.server.fqdn + " -p " + this.props.serverlet.pp + "2";
    }

    getPaidTillString(){
        let paid_till_time = new Date(this.props.serverlet.paid_till_time_unix * 1000);
        return paid_till_time.toLocaleDateString() + " " + paid_till_time.toLocaleTimeString();
    }

    getTillDeleteDaysString(){
        let delete_till_time = new Date(this.props.serverlet.paid_till_time_unix * 1000);
        delete_till_time.setDate(delete_till_time.getDate() + 4);
        let diff_time = Math.abs(delete_till_time - new Date());
        let diff_days = Math.ceil(diff_time / (1000 * 60 * 60 * 24));
        let comment = "dní";
        comment = (diff_days === 1) ? "den" : comment;
        comment = (diff_days > 1 && diff_days < 5) ? "dny" : comment;
        return `${diff_days} ${comment}`;
    }

    render(){
        let usedCpuPerc = 0;
        if(this.props.stats && this.props.bothLoaded){
            usedCpuPerc = (this.props.stats?.cpu_perc / this.props.serverlet?.plan.cpu)
            usedCpuPerc = usedCpuPerc > 100 ? 100 : usedCpuPerc;
        }
        usedCpuPerc = usedCpuPerc.toFixed(1);

        let usedRamPerc = (this.props.stats?.ram_perc ?? 0).toFixed(1);
        usedRamPerc = usedRamPerc > 100 ? 100 : usedRamPerc;

        let usedStoragePerc = (this.props.serverlet?.plan.storage && this.props.storage?.size ? (this.props.storage.size / (this.props.serverlet?.plan.storage))*100 : 0).toFixed(1);
        usedStoragePerc = usedStoragePerc > 100 ? 100 : usedStoragePerc;

        return (
            <Fragment>
                {
                    (this.props.loaded && this.props.serverlet.auto_renew === 0) &&
                    <div className="section">
                        <div className="error-block">
                            Automatické prodloužení je vypnuté. Serverlet právě dobíhá a po uplynutí zaplaceného období bude smazán.
                        </div>
                    </div>
                }

                <div className="section">
                    <div className="resources">
                        <div className="resource cpu">
                            <h2>{ (this.props.loaded) ? this.props.serverlet.plan.cpu : "..." }</h2>
                            <span>vCPU</span>
                            <div className="usage">
                                <progress id="cpu" value={usedCpuPerc} max="100"></progress>
                                <span className="label">{usedCpuPerc}%</span>
                            </div>
                        </div>
                        <div className="resource ram">
                            <h2>{ (this.props.loaded) ? this.props.serverlet.plan.ram + "MB" : "..." }</h2>
                            <span>RAM</span>
                            <div className="usage">
                                <progress id="ram" value={usedRamPerc} max="100"></progress>
                                <span className="label">{usedRamPerc}%</span>
                            </div>
                        </div>
                        <div className="resource storage">
                            <h2>{ (this.props.loaded) ? (this.props.serverlet.plan.storage/1000) + "GB" : "..." }</h2>
                            <span>Uložiště</span>
                            <div className="usage">
                                <progress id="storage" value={usedStoragePerc} max="100"></progress>
                                <span className="label">{usedStoragePerc}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <h1>Přehled</h1>
                    <div className="inner-frame">
                        <table>
                            <tbody>
                            <tr>
                                <td>ID serverletu</td>
                                <td>{ this.props.loaded ? "srvlet" + this.props.serverlet.id : "..."}</td>
                                <td className="right"><button onClick={this.handleCopy} data-text={ this.props.loaded ? "srvlet" + this.props.serverlet.id : "..."} className="copy-button"/></td>
                            </tr>
                            <tr>
                                <td>Interní doména</td>
                                <td>{ (this.props.loaded && this.props.domains[0]) ? this.props.domains[0].name : "..." }</td>
                                <td className="right"><button onClick={this.handleCopy} data-text={ (this.props.loaded && this.props.domains[0]) ? this.props.domains[0].name : "..." } className="copy-button"/></td>
                            </tr>
                            <tr>
                                <td>Fyzický server</td>
                                <td>{ (this.props.loaded) ? this.props.serverlet.server.fqdn : "..." }</td>
                                <td className="right"><button onClick={this.handleCopy} data-text={ (this.props.loaded) ? this.props.serverlet.server.fqdn : "..." } className="copy-button"/></td>
                            </tr>
                            <tr>
                                <td>SSH příkaz</td>
                                <td><code>{ (this.props.loaded) ? this.getSshCommand() : "..." }</code></td>
                                <td className="right"><button onClick={this.handleCopy} data-text={ (this.props.loaded) ? this.getSshCommand() : "..." } className="copy-button"/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="section">
                    <h1>Účtování</h1>
                    <div className="inner-frame">
                        <table>
                            <tbody>
                            <tr>
                                <td>Tarif</td>
                                <td>{ (this.props.loaded) ? this.props.serverlet.plan.name : "..." }</td>
                            </tr>
                            <tr>
                                <td>Cena za hodinu</td>
                                <td>{ (this.props.loaded) ? this.props.serverlet.plan.hourly_price + " Kč" : "..." }</td>
                            </tr>
                            <tr>
                                <td>Cena za měsíc</td>
                                <td>{ (this.props.loaded) ? this.props.serverlet.plan.monthly_price + " Kč" : "..." }</td>
                            </tr>
                            <tr>
                                <td>Účtování</td>
                                <td>{ (this.props.loaded) ? `po ${this.props.serverlet.plan.period} h` : "..." }</td>
                            </tr>
                            <tr>
                                <td>Zaplaceno do</td>
                                <td>{ (this.props.loaded) ? this.getPaidTillString() : "..." }</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="section">
                    <h1>Porty</h1>
                    <div className="inner-frame">
                        <table>
                            <tbody>
                            <tr>
                                <td>Rozsah portů</td>
                                <td>{ this.props.loaded ? this.props.serverlet.pp + "0 - " + this.props.serverlet.pp + "9" : "..."}</td>
                                <td className="right"><button onClick={this.handleCopy} data-text={ this.props.loaded ? this.props.serverlet.pp + "0 - " + this.props.serverlet.pp + "9" : "..."} className="copy-button"/></td>
                            </tr>
                            <tr>
                                <td>SSH port</td>
                                <td>{ this.props.loaded ? this.props.serverlet.pp + "2" : "..."}</td>
                                <td className="right"><button onClick={this.handleCopy} data-text={ this.props.loaded ? this.props.serverlet.pp + "2" : "..."} className="copy-button"/></td>
                            </tr>
                            <tr>
                                <td>LSWS Admin port</td>
                                <td>{ this.props.loaded ? this.props.serverlet.pp + "7" : "..."}</td>
                                <td className="right"><button onClick={this.handleCopy} data-text={ this.props.loaded ? this.props.serverlet.pp + "7" : "..."} className="copy-button"/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default ServerletDashboard;