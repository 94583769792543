import React, {Fragment} from 'react';
import './SideMenu.css';
import {Link} from "react-router-dom";
import {dropByCacheKey} from "react-router-cache-route";

class SideMenu extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            supportLoading : false
        }
        this.menuRef = React.createRef();
        this.shadowRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('menu-open', this.handleMenuOpen, true);
        document.removeEventListener('mousedown', this.handleClickOutside, true);
    }

    componentDidMount(){
        document.addEventListener('menu-open', this.handleMenuOpen, true);
        document.addEventListener('mousedown', this.handleClickOutside, true);
    }

    handleClickOutside(event) {
        if(this.menuRef.current !== null){
            if (this.menuRef && !this.menuRef.current.contains(event.target)) {
                this.handleMenuClose();
            }
        }
    }

    handleMenuOpen(){
        if(!this.menuRef.current.classList.contains("opened")){
            this.menuRef.current.classList.add("opened");
        }
        if(this.shadowRef.current.classList.contains("shadow-hide")){
            this.shadowRef.current.classList.remove("shadow-hide");
        }
        this.shadowRef.current.classList.add("shadow-show");
    }

    handleMenuClose(){
        if(this.menuRef.current.classList.contains("opened")){
            this.menuRef.current.classList.remove("opened");
            this.shadowRef.current.classList.add("shadow-hide");
        }
        if(this.shadowRef.current.classList.contains("shadow-show")){
            this.shadowRef.current.classList.remove("shadow-show");
        }
        this.shadowRef.current.classList.add("shadow-hide");
    }

    componentDidCache(){
        dropByCacheKey('Serverlets');
    }
    addClassActive(
        item_pathname1 = null,
        item_pathname2 = null,
        item_pathname3 = null
    ){
        let firstPart = "/" + this.props.pathname.split('/')[1];
        if(
            item_pathname1 === firstPart ||
            item_pathname2 === firstPart ||
            item_pathname3 === firstPart ||
            item_pathname1 === this.props.pathname ||
            item_pathname2 === this.props.pathname ||
            item_pathname3 === this.props.pathname
        ){
            return " active"
        }
        return "";
    }

    openChat(el){
        document.dispatchEvent(
            new Event("support-open")
        );
        if(!window.tidioChatApi){
            el.target.classList.add("loading");
            document.addEventListener("support-ready", () => {
                el.target.classList.remove("loading");
            });
        }
        if(window.tidioChatApi){
            window.tidioChatApi.open();
        }
    }

    render(){
        return (
            <Fragment>
                <div className="mobile-only shadow" ref={this.shadowRef}/>
                <div className={(this.props.className !== undefined) ? "sidemenu " + this.props.className : "sidemenu"} ref={this.menuRef}>
                    <ul>
                        <li><Link to="/" className={ "dashboard-icon" + this.addClassActive("/") }>Nástěnka</Link></li>
                        <li><Link to="/serverlets" className={ "serverlets-icon" + this.addClassActive("/serverlets", "/serverlet") }>Serverlety</Link></li>
                        <li><Link to="/domains/registrar" className={ "domains-icon" + this.addClassActive("/domains", "/domain") }>Domény</Link></li>
                        <li><div className="delimiter"/></li>
                        <li><Link to="/billing/orders" className={ "billing-icon" + this.addClassActive("/billing") }>Fakturace</Link></li>
                        <li><Link to="#support" onClick={this.openChat}  className={ "support-icon" }>Podpora</Link></li>
                        <li className="desktop-only"><a href="https://help.futrou.com" target="_blank" rel="noreferrer" className={"manual-icon" + this.addClassActive("/manual")}>Nápověda</a></li>
                        <li className="mobile-only"><div className="delimiter"/></li>
                        <li className="mobile-only"><Link to="/settings/email" className={"settings-icon" + this.addClassActive("/settings")}>Nastavení</Link></li>
                        <li className="mobile-only"><Link to="/logout" className="logout-icon">Odhlásit se</Link></li>
                    </ul>
                    <div className="mobile-only">

                    </div>
                </div>
            </Fragment>
        )
    }
}
export default SideMenu;