import React, { Fragment } from 'react';
import "./Serverlet.css";
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import {dropByCacheKey} from "react-router-cache-route";
import { Link } from 'react-router-dom';
import Api from "../ApiLib";
import ServerletDatabases from "./ServerletDatabases";
import ServerletDashboard from "./ServerletDashboard";
import ServerletDomains from "./ServerletDomains";
import ServerletUsers from "./ServerletUsers";
import ServerletSoftware from "./ServerletSoftware";
import ServerletSettings from "./ServerletSettings";
import ServerletUsage from "./ServerletUsage";

class Serverlet extends React.Component{
    constructor(props){
        super(props);
        window.serverlets_sidemenu_hide = true;
        props.router.cacheLifecycles.didCache(this.componentDidCache);
        props.router.cacheLifecycles.didRecover(this.componentDidRecover);
        this.state = {
            loaded : false,
            checkbox : true,
            params : props.router.match.params,
            page : (props.router.match.params.page) ? props.router.match.params.page : "",
            new_domain : "",
            domains : [],
            domainsLoaded : false,
            users : [],
            usersLoaded : false,
            software : [],
            softwareLoaded : false,
            old_user_username : null,
            new_user_username : "",
            new_user_path : "/srv/",
            new_user_password : "",
            old_user_path : "",
            old_user_password : "",
            serverlet : null,
            storage : null,
            stats : null,
            bothLoaded : false,
            ssh_command : null,
        };
        this.checkboxRef = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleServerletAction = this.handleServerletAction.bind(this);
        this.sendAction = this.sendAction.bind(this);
        this.handleRestartButton = this.handleRestartButton.bind(this);
        this.getItemClass = this.getItemClass.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getDomains = this.getDomains.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.getSoftware = this.getSoftware.bind(this);
        this.get = this.get.bind(this);
        this.getStats = this.getStats.bind(this);
        this.getStorage = this.getStorage.bind(this);
        this.refresh();

        this.error_wait_before_next_action = "Počkejte aspoň 10s od provedení předchozí akce.";

        this.updateInterval = null;
    }

    componentDidCache(){
        dropByCacheKey('Serverlet');
    }

    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    refresh(event = null){
        let page = this.state.page;
        if(event){
            this.setState({
                update : true,
                page : event.target.name
            })
            page = event.target.name;
        }
        if(page === "domains" || page === "usage" || page === ""){
            this.getDomains();
        }
        if(page === "users" || page === "usage"){
            this.getUsers();
        }
        if(page === "software"){
            this.getSoftware();
        }
    }

    componentDidMount() {
        this.get();
        this.getStorage();
        this.getStats();
        this.updateInterval = setInterval( () => {
            this.getStats();
        }, 1000 * 30);
    }

    componentWillUnmount() {
        if(this.updateInterval){
            clearInterval(this.updateInterval);
            this.updateInterval = null;
        }
    }

    handleRestartButton(){
        this.sendAction(2);
    }

    sendAction(action_code){
        Api.post(`/serverlet/${this.state.params.id}/action`,{
            action : action_code
        })
            .then(response => response.json())
            .then(res => {
                if(res["success"] === true) {
                    let mess = "Akce byla provedena";
                    mess = (action_code === 0) ? "Serverlet se vypne" : mess;
                    mess = (action_code === 1) ? "Serverlet se zapne" : mess;
                    mess = (action_code === 2) ? "Serverlet se restartuje" : mess;
                    Api.show_message(mess, "success");
                    if(action_code < 2){
                        let new_serverlet = this.state.serverlet;
                        new_serverlet.state = action_code;
                        this.setState({serverlet : new_serverlet});
                    }
                    if(action_code === 1){
                        this.getSoftware();
                    }
                    if(action_code === 2){
                        this.setState({ checkbox : false });
                        setTimeout(() =>{
                            this.setState({ checkbox : true });
                        }, 500);
                    }
                }else{
                    if(action_code < 2){
                        this.setState({
                            checkbox : (action_code) ? false : true
                        });
                    }
                    Api.show_message(this.error_wait_before_next_action, "info");
                }
            });
    }

    handleServerletAction(e) {
        let action = e.target.checked;
        this.setState({
            checkbox : action
        });
        this.sendAction(action ? 1 : 0)
    }

    getItemClass(item_name, item_classname){
        return (this.state.page === item_name) ? item_classname + " active" : item_classname;
    }

    get(){
        Api.get(`/serverlet/${this.state.params.id}`)
            .then(response => response.json())
            .then(res => {
                if(res["success"] === true){
                    this.setState({
                        serverlet : res.serverlet,
                        loaded : true,
                        bothLoaded : (this.state.storage !== null),
                        checkbox : Boolean(res.serverlet.state)
                    });
                }
            });
    }

    getStats(){
        Api.get(`/serverlet/${this.state.params.id}/stats`)
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.setState({
                        stats : res.stats
                    })
                }
            });
    }

    getStorage(){
        Api.get(`/serverlet/${this.state.params.id}/storage`)
            .then(response => response.json())
            .then(res => {
                if(res["success"] === true){
                    this.setState({
                        storage : res.storage,
                        bothLoaded : (this.state.serverlet !== null)
                    });
                }
            });
    }

    getDomains(){
        Api.get(`/serverlet/${this.state.params.id}/domains`)
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.setState({
                        domainsLoaded : true,
                        domains : res.domains
                    })
                }
            });
    }

    getUsers(){
        Api.get(`/serverlet/${this.state.params.id}/users`)
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.setState({
                        usersLoaded : true,
                        users : res.users
                    })
                }
            });
    }


    getSoftware(event = null){
        Api.get(`/serverlet/${this.state.params.id}/software`)
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.setState({
                        softwareLoaded : true,
                        software : res.software
                    })
                    if(event){
                        event.target.classList.remove("loading-button");
                    }
                }
            });
    }

    render(){
        return (
            <Fragment>
                    <Container>
                        <SideMenu className={(window.serverlets_sidemenu_hide) ? "sidemenu-small sidemenu-animate" : "sidemenu-small"} pathname={this.props.router.location.pathname}/>
                        <div className="serverlet_sidemenu">
                            <ul>
                                <li className="desktop-only"><Link to="/serverlets" className="back-icon">Zpět</Link></li>
                                <li className="desktop-only"><div className="delimiter"></div></li>
                                <li><Link onClick={this.refresh} to={"/serverlet/" + this.state.params.id + ""} name="" className={this.getItemClass("","overview-icon")}>Přehled</Link></li>
                                <li><Link onClick={this.refresh} to={"/serverlet/" + this.state.params.id + "/settings"} name="settings" className={this.getItemClass("settings","options-icon")}>Nastavení</Link></li>
                                <li><Link onClick={this.refresh} to={"/serverlet/" + this.state.params.id + "/domains"} name="domains" className={this.getItemClass("domains","domains-icon")}>Domény</Link></li>
                                <li><Link onClick={this.refresh} to={"/serverlet/" + this.state.params.id + "/users"} name="users" className={this.getItemClass("users","users-icon")}>Uživatelé</Link></li>
                                <li><Link onClick={this.refresh} to={"/serverlet/" + this.state.params.id + "/databases"} name="databases" className={this.getItemClass("databases","databases-icon")}>Databáze</Link></li>
                                <li><Link onClick={this.refresh} to={"/serverlet/" + this.state.params.id + "/software"} name="software" className={this.getItemClass("software","software-icon")}>Software</Link></li>
                                <li><Link onClick={this.refresh} to={"/serverlet/" + this.state.params.id + "/usage"} name="usage" className={this.getItemClass("usage","usage-icon")}>Využití</Link></li>
                                <li><Link onClick={this.refresh} to={"/serverlet/" + this.state.params.id + "/cron"} name="cron" className={this.getItemClass("cron","cron-icon")}>Cron</Link></li>
                            </ul>
                        </div>
                            <div className="serverlet_content">
                                <div className={"serverlet_header " + (this.state.loaded ? this.state.serverlet.lang + "-icon" : "") }>
                                    <span className="title">{ (this.state.loaded) ? this.state.serverlet.title : "... ... ..."}</span>
                                    {
                                        this.state.loaded &&
                                        <div className="actions">
                                            {
                                                this.state.checkbox &&
                                                <button onClick={this.handleRestartButton} className="restart">Restart</button>
                                            }
                                            <label className="switch">
                                                <input type="checkbox" ref={this.checkboxRef} onChange={this.handleServerletAction} checked={this.state.checkbox}/>
                                                <span className="slider round">
                                                    <span className="label">{(this.state.checkbox) ? "zap" : "vyp"}</span>
                                                </span>
                                            </label>
                                        </div>
                                    }
                                </div>
                                {
                                    this.state.page === "" &&
                                    <ServerletDashboard
                                        parent={this}
                                        serverlet={this.state.serverlet}
                                        domains={this.state.domains}
                                        users={this.state.users}
                                        storage={this.state.storage}
                                        stats={this.state.stats}
                                        loaded={this.state.loaded}
                                        bothLoaded={this.state.bothLoaded}
                                    />
                                }
                                {
                                    this.state.page === "settings" &&
                                    <ServerletSettings
                                        parent={this}
                                        serverlet={this.state.serverlet}
                                        loaded={this.state.loaded}
                                        auth={this.props.auth}
                                    />
                                }
                                {
                                    this.state.page === "domains" &&
                                    <ServerletDomains
                                        parent={this}
                                        serverlet={this.state.serverlet}
                                        domains={this.state.domains}
                                        domainsLoaded={this.state.domains}
                                    />
                                }
                                {
                                    this.state.page === "users" &&
                                    <ServerletUsers
                                        parent={this}
                                        serverlet={this.state.serverlet}
                                        users={this.state.users}
                                        usersLoaded={this.state.usersLoaded}
                                    />
                                }
                                {
                                    this.state.page === "databases" &&
                                    <ServerletDatabases/>
                                }
                                {
                                    this.state.page === "software" &&
                                    <ServerletSoftware
                                        parent={this}
                                        serverlet={this.state.serverlet}
                                        software={this.state.software}
                                        softwareLoaded={this.state.softwareLoaded}
                                    />
                                }
                                {
                                    this.state.page === "usage" &&
                                    <ServerletUsage
                                        parent={this}
                                        serverlet={this.state.serverlet}
                                        domains={this.state.domains}
                                        users={this.state.users}
                                        storage={this.state.storage}
                                        stats={this.state.stats}
                                        loaded={this.state.loaded}
                                        bothLoaded={this.state.bothLoaded}
                                    />
                                }
                                {
                                    this.state.page === "cron" &&
                                    <Fragment/>
                                }
                                {
                                    this.state.page === "graphs" &&
                                    <Fragment/>
                                }
                            </div>
                    </Container>
            </Fragment>
        );
    }
}
export default Serverlet;