import Api from "./ApiLib";

class Auth{
    constructor(updateFunc){
        this.update = updateFunc;
        this.loaded = false;
        this.authenticated = false;
        this.logged_out = false;
        this.user = null;
        this.enter();
        this.get_serverlet_plans();
        setInterval(() => {
            if(this.authenticated){
                this.enter();
            }
        }, 1000 * 60 * 60);
    }

    is_authenticated(response){
        if(response["authenticated"] !== true){
            this.authenticated = false;
        }
    }

    async logout(){
        await Api.post("/auth/logout")
            .then(response => response.json())
            .then(response => {
                this.authenticated = false;
                this.logged_out = true;
                this.update();
            })
            .catch((error) => {
                this.update();
            });
    }

    async enter(){
        await Api.get("/user/enter")
            .then(response => response.json())
            .then(response => {
                this.authenticated = response["authenticated"];
                this.user = (this.authenticated) ? response["user"] : null;
                this.version = response["version"];
                this.generator = response["generator"];
                this.bug_report = response["bug-report"];
                this.loaded = true;
                this.update();
            })
            .catch((error) => {
                this.loaded = false;
                this.update();
            });
    }

    get_serverlet_plans(){
        let plans = localStorage.getItem("serverlet_plans");
        plans = JSON.parse(plans);
        return (plans) ? Object.values(plans) : [];
    }

    async serverlet_plans(){
        await Api.get("/serverlets/plans")
            .then(response => response.json())
            .then(response => {
                localStorage.setItem("serverlet_plans", JSON.stringify(response["plans"]));
            })
            .catch((error) => {
            });
    }

    async verify(email,password){
        let parent = this;
        return await Api.post('/auth/login', {
            email : email,
            password : password
        })
        .then(response => response.json())
        .then(response => {
            parent.authenticated = response["success"];
            return {
                result : response["success"],
                response : response
            }
        })
        .catch((error) => {
            return {
                result : false
            };
        });
    }

    async signup(email, password, personal, firstname, lastname, company, street, streetNo, postalCode, city, country, ic, dic){
        return await Api.post('/auth/signup', {
            email : email,
            password : password,
            personal : personal,
            firstname : firstname,
            lastname : lastname,
            company : company,
            street : street,
            street_no : streetNo,
            postal_code : postalCode,
            city : city,
            country : country,
            ic : ic,
            dic : dic
        })
            .then(response => response.json())
            .then(response => {
                return {
                    result : response["success"],
                    response : response
                }
            })
            .catch((error) => {
                return {
                    result : false
                };
            });
    }

    async getSignupState(){
        return await Api.post('/auth/signup/state', {})
            .then(response => response.json())
            .then(response => {
                return {
                    result : response["success"],
                    response : response
                }
            })
            .catch((error) => {
                return {
                    result : false
                };
            });
    }

    async resetSignupState(){
        return await Api.post('/auth/signup/state/reset', {})
            .then(response => response.json())
            .then(response => {
                return {
                    result : response["success"],
                    response : response
                }
            })
            .catch((error) => {
                return {
                    result : false
                };
            });
    }

    async finishSignup(code){
        return await Api.post('/auth/signup/finish', {
            code : code
        })
            .then(response => response.json())
            .then(response => {
                return {
                    result : response["success"],
                    response : response
                }
            })
            .catch((error) => {
                return {
                    result : false
                };
            });
    }

    async recovery(email){
        return await Api.post('/auth/recovery', {
            email : email
        })
            .then(response => response.json())
            .then(response => {
                return {
                    result : response["success"],
                    response : response
                }
            })
            .catch((error) => {
                return {
                    result : false
                };
            });
    }

    async recoveryVerifyCode(code){
        return await Api.post('/auth/recovery/verify', {
            code : code
        })
            .then(response => response.json())
            .then(response => {
                return {
                    result : response["success"],
                    response : response
                }
            })
            .catch((error) => {
                return {
                    result : false
                };
            });
    }

    async resetRecoveryState(){
        return await Api.post('/auth/recovery/state/reset', {})
            .then(response => response.json())
            .then(response => {
                return {
                    result : response["success"],
                    response : response
                }
            })
            .catch((error) => {
                return {
                    result : false
                };
            });
    }

    async finishRecovery(code, password){
        return await Api.post('/auth/recovery/finish', {
            code : code,
            password : password
        })
            .then(response => response.json())
            .then(response => {
                return {
                    result : response["success"],
                    response : response
                }
            })
            .catch((error) => {
                return {
                    result : false
                };
            });
    }
}
export default Auth;