import React, { Fragment } from 'react';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import {dropByCacheKey} from "react-router-cache-route";
import LazyLoad from 'react-lazyload';
import "./Orders.css"
import "../components/HorizontalMenu/HorizontalMenu.css"
import Api from "../ApiLib"
import RevolutCheckout from "@revolut/checkout"
import OrdersMenu from "../components/OrdersMenu/OrdersMenu";

class Orders extends React.Component{
    constructor(props){
        super(props);
        this.reload = this.reload.bind(this);
        this.cardFrom = React.createRef();
        this.cardInstance = React.createRef();
        this.cardBLRef = React.createRef();
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleOrderOpen = this.handleOrderOpen.bind(this);
        this.orderExists = this.orderExists.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.setAsOpen = this.setAsOpen.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.hideElement = this.hideElement.bind(this);
        this.openCard = this.openCard.bind(this);
        this.closeCard = this.closeCard.bind(this);
        this.handleCardContainerClick = this.handleCardContainerClick.bind(this);
        this.payCard = this.payCard.bind(this);
        this.cardFailed = this.cardFailed.bind(this);
        this.cardSuccess = this.cardSuccess.bind(this);
        this.state = {
            supplier : null,
            orders : [],
            ordersLoaded : false,
            orderOpened : [],
            cardOpen : false,
            cardError : false,
            cardSubmit : false,
            cardTriedSubmit : false,
            cardOrderId : 0
        };
        this.payments = {
            0 : "Převodem",
            1 : "Platební kartou",
            2 : "Dárkovým poukazem"
        };
    }

    reload(){
        dropByCacheKey('Orders');
    }

    componentDidMount() {
        this.getOrders();
    }

    cardSuccess(){
        this.closeCard();
        Api.show_message("Platba byla úspěšně provedena", "success");
    }
    cardFailed(){
        this.closeCard();
        Api.show_message("Platba se nezdařila.", "error");
    }
    payCard(){
        if(!this.state.cardError){
            this.setState({
                cardTriedSubmit : true,
                cardSubmit : true
            })
            let meta = {
                name : this.props.auth.user.contact.firstname + " " + this.props.auth.user.contact.lastname,
                email : this.props.auth.user.email,
                billingAddress : {
                    countryCode : this.props.auth.user.contact.country.toUpperCase(),
                    city : this.props.auth.user.contact.city,
                    streetLine1 : this.props.auth.user.contact.street + " " + this.props.auth.user.contact.street_no,
                    postcode : this.props.auth.user.contact.postal_code.toString()            }
            };
            this.cardInstance.submit(meta);
        }else{
            this.setState({
                cardTriedSubmit : true
            })
        }
    }

    closeCard(event){
        if(this.cardInstance !== null){
            this.cardInstance.destroy();
        }
        this.cardInstance = null;
        this.setState({
            cardOpen : false,
            cardSubmit : false,
            cardError : false,
            cardOrderId : 0
        });
    }
    openCard(event){
        let parent = this;
        let merchant_id = event.target.getAttribute("data-merchant-id");
        let order_id = parseInt(event.target.getAttribute("data-id"));

        RevolutCheckout(merchant_id, "sandbox")
            .then(function (instance) {
                parent.setState({
                    cardOpen : true,
                    cardOrderId : order_id
                });
                parent.cardInstance = instance.createCardField({
                    target: parent.cardFrom.current,
                    hidePostcodeField: true,
                    locale : "cs",
                    styles: {
                        default: {
                            color: "#202020",
                            "::placeholder": {
                                color: "rgba(0,0,0,0)"
                            }
                        },
                        autofilled: {
                            color: "#202020"
                        }
                    },
                    onSuccess() {
                        parent.cardSuccess();
                    },
                    onError(message) {
                        parent.cardFailed();
                        console.log(message);
                    },
                    onValidation(mes){
                        parent.setState({
                            cardError : (mes.length > 0),
                            cardSubmit : (parent.state.cardSubmit && (mes.length === 0))
                        })
                        console.log(mes);
                        console.log(parent.state.cardError)
                    }
                });
            }).catch(error =>{
                Api.show_message("Nepodařilo se kontaktovat platební bránu. Zkuste to prosím později.", "error")
            });
    }

    getOrders(){
        Api.get("/orders")
            .then(res => res.json())
            .then(res => {
                if(res.authenticated){
                    this.setState({
                        supplier : res.supplier,
                        storage : res.storage,
                        orders : res.orders,
                        ordersLoaded : true
                    });
                }
            });
    }

    orderExists(id){
        let exists = false;
        this.state.orders.forEach(item => {
            if(id === item.id){
                exists = true;
            }
        })
        return exists;
    }

    cancelOrder(event){
        let id = parseInt(event.target.getAttribute("data-id"));
        let inner_id = parseInt(event.target.getAttribute("data-inner_id"));
        if(this.orderExists(id)){
            Api.delete(`/orders/${inner_id}`,{
            })
                .then(res => res.json())
                .then(res => {
                    if(res.authenticated){
                        if(res.success){
                            this.setAsOpen(id);
                            this.getOrders();
                            Api.show_message("Objednávka byla stornována","success")
                        }else{
                            Api.show_message("Objednávku se nepodařilo stornovat","error")
                        }
                    }
                });
        }
    }

    setAsOpen(id){
        let orderOpened = this.state.orderOpened;
        orderOpened[id] = (id === this.state.orderOpened[id]) ? null : id;
        this.setState({
            orderOpened : orderOpened
        })
    }

    handleOrderOpen(event){
        let id = parseInt(event.target.getAttribute("data-id"));
        let canceled = parseInt(event.target.getAttribute("data-canceled"));
        if(!canceled){
            this.setAsOpen(id);
        }
    }

    handleCopy(event){
        navigator.clipboard.writeText(
            event.target.getAttribute("data-text")
        );
        Api.show_message("Zkopírováno", "info");
    }

    hideElement(event){
        event.target.classList.add("hide");
    }

    handleCardContainerClick(event){
        let action = (event.target.getAttribute("data-name")) ?? null;
        if(action === "close"){
            this.closeCard();
        }
    }

    render(){
        let orders = [];
        if(this.state.orders !== null){
            this.state.orders.forEach(item => {
                let created = new Date(item.created_time_unix * 1000);
                let paid = (item.invoice) ? new Date(item.invoice.paid_time_unix * 1000) : null;
                let state = "Čeká na zaplacení";
                state = (item.paid) ? "Zaplacena" : state;
                state = (item.canceled) ? "Stornována" : state;
                let stateClass = "waiting";
                stateClass = (item.paid) ? "paid" : stateClass;
                stateClass = (item.canceled) ? "canceled" : stateClass;
                let openedClass = (this.state.orderOpened[item.id] === item.id) ? "opened" : "";
                let qr_url = (item.pinvoice) ? this.state.storage.url + "pinvoices/" + item.pinvoice.location + ".qr.svg" : "";
                let pinvoice_url = (item.pinvoice) ? this.state.storage.url + "pinvoices/" + item.pinvoice.location + ".pdf" : "";
                let invoice_url = (item.invoice) ? this.state.storage.url + "invoices/" + item.invoice.location + ".pdf" : "";

                orders.push(
                    <Fragment key={item.id}>
                        <div onClick={this.handleOrderOpen} className={"order " + stateClass + " " + openedClass} data-canceled={item.canceled} data-id={item.id} key={item.id}>
                            <span className="id" data-id={item.id} data-canceled={item.canceled}>{item.inner_id}</span>
                            <span className="created" data-id={item.id} data-canceled={item.canceled}>{created.toLocaleDateString()}</span>
                            <span className="state" data-id={item.id} data-canceled={item.canceled}><span data-id={item.id} data-canceled={item.canceled} className={stateClass}>{state}</span></span>
                            <span className="credit" data-id={item.id} data-canceled={item.canceled}>{item.credit} Kč</span>
                        </div>
                        {
                            (this.state.orderOpened[item.id] === item.id) &&
                            <div className="order-detail" key={item.id + "detail"}>
                                {
                                    (!Boolean(item.paid) && (item.payment === 0)) &&
                                    <Fragment>
                                        <div className="top">
                                            <p>
                                                Způsob platby:
                                                <span>{this.payments[item.payment]}</span>
                                                <button onClick={this.handleCopy} data-text={this.payments[item.payment]} className="copy-button"/>
                                            </p>
                                            <p>
                                                Variabilní symbol:
                                                <span>{item.inner_id}</span>
                                                <button onClick={this.handleCopy} data-text={item.inner_id} className="copy-button"/>
                                            </p>
                                            <p>
                                                Účet:
                                                <span>{this.state.supplier.bank_account}</span>
                                                <button onClick={this.handleCopy} data-text={this.state.supplier.bank_account} className="copy-button"/>
                                            </p>
                                            <p>
                                                Banka:
                                                <span>{this.state.supplier.bank}</span>
                                                <button onClick={this.handleCopy} data-text={this.state.supplier.bank} className="copy-button"/>
                                            </p>
                                            <p>
                                                IBAN:
                                                <span>{this.state.supplier.iban}</span>
                                                <button onClick={this.handleCopy} data-text={this.state.supplier.iban} className="copy-button"/>
                                            </p>
                                            <p>
                                                BIC/SWIFT:
                                                <span>{this.state.supplier.swift}</span>
                                                <button onClick={this.handleCopy} data-text={this.state.supplier.swift} className="copy-button"/>
                                            </p>
                                        </div>
                                        <div className="qr">
                                            <LazyLoad>
                                                <img src={qr_url} alt="Payment QR Code" onError={this.hideElement}/>
                                            </LazyLoad>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    (!Boolean(item.paid) && (item.payment === 1)) &&
                                    <Fragment>
                                        <div className="top">
                                            <p>
                                                Způsob platby:
                                                <span>{this.payments[item.payment]}</span>
                                                <button onClick={this.handleCopy} data-text={this.payments[item.payment]} className="copy-button"/>
                                            </p>
                                        </div>
                                        <div onClick={this.handleCardContainerClick} data-name="close" className={(this.state.cardOpen && this.state.cardOrderId === item.inner_id) ? "card-container":"hide"}>
                                            <div className="card-box">
                                                <div className={(this.state.cardSubmit) ? "hide-vis" : "card-before-loading"} ref={this.cardBLRef}>
                                                    <div className="card-order">Objednávka: <span>{item.inner_id}</span></div>
                                                    <div className="card-form-container">
                                                        <div className="card-label"/>
                                                        <div className="card-label-num">Číslo karty</div>
                                                        <div className="card-label-exp">MM/RR</div>
                                                        <div className="card-label-cvc">CVC</div>
                                                        <div className="card-form" ref={this.cardFrom}/>
                                                    </div>
                                                    {
                                                        (this.state.cardTriedSubmit && this.state.cardError) &&
                                                        <div className="error">Neplatné údaje. Zkontrolujte je prosím.</div>
                                                    }
                                                    <div className="bottom-info">Objednávka bude uhrazena skrze platební bránu Revolut. Z Vašeho účtu bude strženo {item.merchant_price} Kč</div>
                                                    <input onClick={this.payCard} type="submit" value={`Zaplatit ${item.merchant_price} Kč`}/>
                                                </div>
                                                <button onClick={this.handleCardContainerClick} data-name="close" className="close">Zavřít platební bránu</button>
                                            </div>
                                        </div>
                                        <div className="card-popup">
                                            Potvrďte prosím tuto <br/>platbu u své banky.
                                            <button onClick={this.handleCardContainerClick} data-name="close" className="close">Zrušit platbu</button>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    Boolean(item.paid) &&
                                    <div className="top">
                                        <p>
                                            Zaplaceno:
                                            <span>{paid.toLocaleDateString()} {paid.toLocaleTimeString()}</span>
                                        </p>
                                    </div>
                                }
                                <div className="bottom">
                                    {
                                        (item.invoice) &&
                                        <a className="pinvoice" href={invoice_url} rel="noreferrer" target="_blank">
                                            <h3>Faktura</h3>
                                            <h2>{item.invoice.number}</h2>
                                        </a>
                                    }
                                    {
                                        (item.pinvoice) &&
                                        <a className="pinvoice" href={pinvoice_url} rel="noreferrer" target="_blank">
                                            <h3>Proforma faktura</h3>
                                            <h2>{item.pinvoice.number}</h2>
                                        </a>
                                    }
                                    {
                                        ((item.payment === 1) && !Boolean(item.paid)) &&
                                        <button className="pay-with-card" onClick={this.openCard} data-id={item.inner_id} data-merchant-id={item.merchant_id}>Zaplatit kartou</button>
                                    }
                                </div>
                                <div className="buttons">
                                    {
                                        (!Boolean(item.canceled) && !Boolean(item.paid)) &&
                                        <button className="cancel" onClick={this.cancelOrder} data-id={item.id} data-inner_id={item.inner_id}>Stornovat</button>
                                    }
                                </div>
                            </div>
                        }
                    </Fragment>
                )
            });
        }
        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <OrdersMenu pathname={this.props.router.location.pathname}/>
                    <Content>

                        <div className="section">
                            {
                                (this.state.ordersLoaded && this.state.orders.length !== 0) &&
                                <Fragment>
                                    <span className="count">{this.state.orders.length}</span>
                                    <h1>Objednávky</h1>

                                    <div className="orders-head">
                                        <span className="id">Číslo objednávky</span>
                                        <span className="state">Stav</span>
                                        <span className="credit">Částka</span>
                                    </div>
                                    <div className="orders-list">
                                        {orders}
                                    </div>
                                </Fragment>
                            }
                            {
                                !this.state.ordersLoaded &&
                                <div className="orders-none"><span>Načítám...</span></div>
                            }
                            {
                                (this.state.ordersLoaded && this.state.orders.length === 0) &&
                                <div className="orders-none"><span>Žádné objednávky</span></div>
                            }
                        </div>
                    </Content>
                </Container>
            </Fragment>
        );
    }
}
export default Orders;