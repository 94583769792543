import React from 'react';
import "./ServerletItem.css"
import {dropByCacheKey} from "react-router-cache-route";
import { Link } from 'react-router-dom';

class ServerletItem extends React.Component{
    componentDidCache(){
        dropByCacheKey('ServerletItem');
    }
    render(){
        return (
            <Link className={"serverlet-item " + this.props.data.lang + "-icon" } to={ "/serverlet/" +  this.props.data.id } style={{ textDecoration: 'none' }}>
                <span className="title">{this.props.data.title}</span>
                <div className="plan">{this.props.data.plan.prefix} {this.props.data.plan.name}</div>
                {
                    this.props.data.active === 0 &&
                    <div className="info inactive">Pozastaven</div>
                }
                {
                    (this.props.data.active === 1 && this.props.data.autorenew === 0) &&
                    <div className="info">Dobíhá</div>
                }
            </Link>
        )
    }
}
export default ServerletItem;