import React, { Fragment } from 'react';
import "./Serverlets.css"
import Api from "../ApiLib"

class ServerletUsers extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            old_user_username : "",
            old_user_path : "",
            old_user_password : "",
            new_user_username : "",
            new_user_path : "/srv/",
            new_user_password : "",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addUser = this.addUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.handleUserDelete =this.handleUserDelete.bind(this);
        this.editUserShow = this.editUserShow.bind(this);
        this.editUserSave = this.editUserSave.bind(this);
        this.handlePathChange = this.handlePathChange.bind(this);

        this.success_user_added = "Uživatel přidán.";
        this.success_user_edited = "Uživatel upraven.";
        this.success_user_deleted = "Uživatel smazán.";
        this.error_user_add_generic = "Uživatele se nepodařilo přidat.";
        this.error_user_add_exists = "Uživatel s tímto jménem již existuje.";
        this.error_user_add_username_format = "Chybný formát uživatelského jména.";
        this.error_user_add_username_length = "Minimální délka uživatelského jména jsou 4 znaky a maximální 100.";
        this.error_user_add_path_format = "Chybný formát adresáře.";
        this.error_user_add_empty_username = "Zadejte uživatelské jméno.";
        this.error_user_add_blocked = "Použití tohoto uživatelského jména je zakázáno.";
        this.error_user_add_max = "Dosáhli jste limitu maximálního počtu uživatelů.";
        this.error_user_edit_generic = "Uživatele se nepodařilo upravit.";
        this.error_user_delete_generic = "Uživatele se nepodařilo smazat.";
        this.error_user_password_length = "Minimální délka hesla je 10 znaků a maximální 100.";
    }

    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    addUser(){
        Api.post(`/serverlet/${this.props.parent.state.params.id}/users`,{
            username : this.state.new_user_username,
            path : this.state.new_user_path,
            password : this.state.new_user_password
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.setState({
                        new_user_username : "",
                        new_user_path : "/srv/",
                        new_user_password : ""
                    })
                    this.props.parent.getUsers();
                    Api.show_message(this.success_user_added, "success");
                }else{
                    let mes = this.error_user_add_generic;
                    if(res.error){
                        mes = (res.error.list[0] === "blocked-username") ? this.error_user_add_blocked : mes;
                        mes = (res.error.list[0] === "empty") ? this.error_user_add_empty_username : mes;
                        mes = (res.error.list[0] === "wrong-format") ? this.error_user_add_username_format : mes;
                        mes = (res.error.list[0] === "wrong-path") ? this.error_user_add_path_format : mes;
                        mes = (res.error.list[0] === "max-users") ? this.error_user_add_max : mes;
                        mes = (res.error.list[0] === "user-exists") ? this.error_user_add_exists : mes;
                        mes = (res.error.list[0] === "invalid-length") ? this.error_user_password_length: mes;
                    }
                    Api.show_message(mes, "error");
                }
            });
    }

    handleUserDelete(event){
        let username = event.target.getAttribute("data-username");
        this.deleteUser(username);
    }


    deleteUser(username){
        Api.delete(`/serverlet/${this.props.parent.state.params.id}/users/${username}`,{
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.props.parent.getUsers();
                    Api.show_message(this.success_user_deleted, "success");
                }else{
                    Api.show_message(this.error_user_delete_generic, "error");
                }
            });
    }


    editUserSave(event){
        Api.put(`/serverlet/${this.props.parent.state.params.id}/users/${this.state.old_user_username}`,{
            path : this.state.old_user_path,
            password : this.state.old_user_password
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    this.setState({
                        old_user_username : "",
                        old_user_path : "",
                        old_user_password : ""
                    })
                    this.props.parent.getUsers();
                    Api.show_message(this.success_user_edited, "success");
                }else{
                    let mes = this.error_user_add_generic;
                    if(res.error){
                        mes = (res.error.list[0] === "blocked-username") ? this.error_user_add_blocked : mes;
                        mes = (res.error.list[0] === "empty") ? this.error_user_add_empty_username : mes;
                        mes = (res.error.list[0] === "wrong-format") ? this.error_user_add_username_format : mes;
                        mes = (res.error.list[0] === "wrong-path") ? this.error_user_add_path_format : mes;
                        mes = (res.error.list[0] === "max-users") ? this.error_user_add_max : mes;
                        mes = (res.error.list[0] === "user-exists") ? this.error_user_add_exists : mes;
                        mes = (res.error.list[0] === "invalid-length") ? this.error_user_password_length: mes;
                    }
                    Api.show_message(mes, "error");
                }
            });
    }

    editUserShow(event){
        let username = event.target.getAttribute("data-username");
        let path = event.target.getAttribute("data-path");
        this.setState({
            old_user_username : username,
            old_user_path : path
        })
    }

    handlePathChange(event) {
        let path = event.target.value;
        let first_part = path.substring(0, path.indexOf("/", path.indexOf("/") + 1) + 1);
        if(first_part === "/srv/"){
            this.handleInputChange(event);
        }
    }

    render(){
        let users = [];
        if(this.props.users.length > 0 && this.props.serverlet !== null){
            this.props.users.forEach(item => {
                let edit_open = this.state.old_user_username === item.username;
                edit_open = (this.props.serverlet.state === 0) ? false : edit_open;
                users.push(
                    <Fragment key={item.id}>
                        <tr key={item.id}>
                            <td>{item.username}</td>
                            <td>{item.path}</td>
                            <td className="right">
                                {
                                    this.props.serverlet.state === 1 &&
                                    <Fragment>
                                        {
                                            edit_open &&
                                            <button onClick={this.editUserSave} data-username={item.username} className="edit-button">Uložit</button>
                                        }
                                        {
                                            !edit_open &&
                                            <button onClick={this.editUserShow} data-path={item.path} data-username={item.username} className="edit-button">Upravit</button>
                                        }
                                        {
                                            !item.admin &&
                                            <button onClick={this.handleUserDelete} data-username={item.username} className="delete-button"/>
                                        }
                                    </Fragment>
                                }
                            </td>
                        </tr>
                        {
                            this.props.serverlet.state === 1 &&
                            <Fragment>
                                {
                                    edit_open &&
                                    <tr className="edit-tr" key={item.id + "1"}>
                                        <td colSpan="3">
                                            <div className="edit-box">
                                                <label htmlFor="old_user_path">Nový adresář</label>
                                                <input onChange={this.handlePathChange} type="text" id="old_user_path" name="old_user_path" value={this.state.old_user_path}/><br/>

                                                <label htmlFor="old_user_password">Nové heslo</label>
                                                <input onChange={this.handleInputChange} type="password" id="old_user_password" name="old_user_password" value={this.state.old_user_password}/><br/>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </Fragment>
                        }
                    </Fragment>
                );
            });
        }

        return (
            <Fragment>
                <div className="section">
                    <h1 className="padded">Uživatelé</h1>
                    <span className="count">{this.props.users.length}</span>
                    {
                        !this.props.usersLoaded &&
                        <div className="inner-none"><span>Načítám...</span></div>
                    }
                    {
                        this.props.usersLoaded &&
                        <Fragment>
                            {
                                this.props.users.length > 0 &&
                                <div className="inner-frame users-list">
                                    <div className="list">
                                        <table>
                                            <thead>
                                            <tr>
                                                <td>Jméno</td>
                                                <td>Adresář</td>
                                                <td></td>
                                            </tr>
                                            </thead>
                                            <tbody>{users}</tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                this.props.users.length === 0 &&
                                <div className="inner-none"><span>Žádní uživatelé</span></div>
                            }
                            {
                                (this.props.serverlet && this.props.serverlet.state === 1) &&
                                <div className="inner-input">
                                </div>
                            }
                            {
                                (this.props.serverlet && this.props.serverlet.state === 0) &&
                                <div className="full-warning">Pro úpravu uživatelů musí být serverlet zapnutý</div>
                            }
                        </Fragment>
                    }
                </div>
                {
                    (this.props.serverlet && this.props.serverlet.state === 1) &&
                    <div className="section">
                        <h1>Nový uživatel</h1>
                        <div className="inner-blank user-add-box edit-tr">
                            <label htmlFor="new_user_username">Uživatel</label>
                            <input value={this.state.new_user_username} onChange={this.handleInputChange} type="text" id="new_user_username" name="new_user_username"/><br/>
                            <label htmlFor="new_user_path">Adresář</label>
                            <input value={this.state.new_user_path} onChange={this.handlePathChange} type="text" id="new_user_path" name="new_user_path"/><br/>
                            <label htmlFor="new_user_password">Heslo</label>
                            <input value={this.state.new_user_password} onChange={this.handleInputChange} type="password" id="new_user_password" name="new_user_password"/><br/>
                            <label/>
                            <input onClick={this.addUser} type="submit" name="user_submit" value="Přidat uživatele"/><br/>
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}
export default ServerletUsers;