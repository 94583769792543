import React, {Fragment} from 'react';
import './PageAlert.css';

class PageAlert extends React.Component{
    constructor(props) {
        super(props);
        this.handleAlert = this.handleAlert.bind(this);
        this.optionNo = this.optionNo.bind(this);
        this.optionYes = this.optionYes.bind(this);
        document.addEventListener('page_alert', this.handleAlert, true);
        this.state = {
            show : false,
            message : null,
            function : null
        }
    }

    handleAlert(event){
        if(event.detail.message){
            this.setState({
                show : true,
                message : event.detail.message,
                function : event.detail.func
            })
        }
        console.log(event.detail.func);
    }

    optionNo(){
        this.setState({
            show : false
        });
    }

    optionYes(){
        this.setState({
            show : false
        });
        if(this.state.function){
            this.state.function();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('page_message', this.handleMessage, true);
    }

    componentDidMount(){
    }


    render() {
        if(this.state.show){
            return(
                <div className="page_alert_container">
                    <div className="page_alert">
                        <h2>{this.state.message}</h2>
                        <button onClick={this.optionNo}>Ne</button>
                        <button onClick={this.optionYes}>Ano</button>
                    </div>
                </div>
            )
        }
        return (
            <Fragment/>
        )
    }
}
export default PageAlert;