import React, { Fragment } from 'react';
import SideMenu from "../components/SideMenu/SideMenu";
import Container from "../containers/Container.js";
import Content from "../containers/Content.js";
import Api from "../ApiLib";
import DomainDnsItem from "../components/DomainDnsItem/DomainDnsItem";
import DomainsMenu from "../components/DomainsMenu/DomainsMenu";

class DomainsDNS extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            domains_dns : []
        }
        props.router.cacheLifecycles.didRecover(this.componentDidRecover);
    }

    componentDidRecover(){

    }

    componentDidMount() {
        this.load();
    }


    load(){
        Api.get("/domains/dns")
            .then(response => response.json())
            .then(data => {
                if(data.success && data.authenticated === true){
                    if(data.domains_dns){
                        this.setState({
                            domains_dns : data.domains_dns
                        });
                    }
                }
            });
    }

    render(){
        let domains_dns = [];
        if(this.state.domains_dns !== null){
            Object.values(this.state.domains_dns).forEach(item => {
                domains_dns.push(
                    <DomainDnsItem key={item.id} id={item.id} data={item}/>
                );
            });
        }
        return (
            <Fragment>
                <Container>
                    <SideMenu pathname={this.props.router.location.pathname}/>
                    <DomainsMenu pathname={this.props.router.location.pathname}/>
                    <Content>
                        <div className="section">
                            <h1 className="padded">DNS zóny</h1>
                            <span className="count">{domains_dns.length}</span>
                            <div className={domains_dns.length > 0 ? "domains-dns-list" : "domains-dns-list domains-dns-none" }>
                                {domains_dns.length < 1 && <span>Žádné zóny</span>}
                                {domains_dns}
                            </div>
                        </div>
                    </Content>
                </Container>
            </Fragment>
        )
    }
}
export default DomainsDNS;