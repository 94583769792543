import React from 'react';
import './HorizontalMenu.css';
import {Link} from "react-router-dom";
import {dropByCacheKey} from "react-router-cache-route";

class HorizontalMenu extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            items : this.props.items !== undefined ? this.props.items : []
        }
    }

    componentDidCache(){
        dropByCacheKey('HorizontalMenu');
    }
    addClassActive(
        item_pathname1 = null,
        item_pathname2 = null,
        item_pathname3 = null
    ){
        let firstPart = "/" + this.props.pathname.split('/')[1];
        if(
            item_pathname1 === firstPart ||
            item_pathname2 === firstPart ||
            item_pathname3 === firstPart ||
            item_pathname1 === this.props.pathname ||
            item_pathname2 === this.props.pathname ||
            item_pathname3 === this.props.pathname
        ){
            return " active"
        }
        return "";
    }
    render(){
        let links = [];
        let i = 0;
        this.state.items.forEach(item => {
            links.push(
                <li key={i}><Link to={item.to} className={ item.className + this.addClassActive(item.to) }>{item.title}</Link></li>
            );
            i++;
        });

        return (
            <div className="horizontal_menu">
                <ul>
                    {links}
                </ul>
            </div>
        )
    }
}
export default HorizontalMenu;