import React, { Fragment } from 'react';
import {dropByCacheKey} from "react-router-cache-route";
import "../generic.css"
import "./Serverlets.css"
import "./ServerletSettings.css"
import Api from "../ApiLib"
import SelectList from "../components/SelectList/SelectList";
import {Redirect} from "react-router-dom";

class ServerletSettings extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loaded: this.props.loaded,
            title: this.props.loaded ? this.props.serverlet.title : "",
            auto_renew: this.props.loaded ? this.props.serverlet.auto_renew === 1 : null,
            plan: this.props.loaded ? this.props.serverlet.plan : null,

            runtime : this.props.loaded ? this.props.serverlet.runtime : null,
            runtimes: [],

            lang: this.props.loaded ? { name : this.props.serverlet.lang } : null,
            langs: this.props.loaded ? this.props.serverlet.runtime.langs : null,

            reinstall_confirm : "",
            delete_confirm : "",
            deleting : false
        };

        this.plans = this.props.auth.get_serverlet_plans();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.changeTitle = this.changeTitle.bind(this);
        this.changeRuntime = this.changeRuntime.bind(this);
        this.changeLang = this.changeLang.bind(this);
        this.changeAutorenew = this.changeAutorenew.bind(this);
        this.selectRuntime = this.selectRuntime.bind(this);
        this.selectLang = this.selectLang.bind(this);
        this.reinstall = this.reinstall.bind(this);
        this.delete = this.delete.bind(this);

        this.success_rename = "Serverlet byl přejmenován.";
        this.error_rename_generic = "Přejmenování se nezdařilo.";
        this.error_rename_empty = "Název nesmí být prázdný.";
        this.error_rename_format = "Název má špatný formát.";
        this.error_rename_length = "Maximální délka názvu je 255 znaků";
        this.success_auto_renew = "Automatické prodlužování bylo aktualizováno";
        this.error_auto_renew = "Aktualizace se nezdařila";

        this.success_lang_change = "Jazyk byl úspěšně změněn";
        this.error_lang_change = "Jazyk se nepodařilo změnit";
        this.error_lang_change_nochange = "Zvolený jazyk je stejný jako stávající";
        this.error_lang_change_notsupported = "Jazyk není podporován stávajícím obrazem serverletu.";

        this.success_runtime_change = "Obraz byl úspěšně změněn";
        this.error_runtime_change = "Obraz se nepodařilo změnit";
        this.error_runtime_change_nochange = "Zvolený obraz je stejný jako stávající";
        this.error_runtime_change_unavailable_lang = "Zvolený obraz nepodporuje současně nastavený jazyk."
        this.error_runtime_change_too_early = "Počkejte alespoň 30s před další změnou obrazu. Na pozadí mohou stále probíhat procesy."

        this.success_reinstall = "Serverlet bude reinstalován";
        this.error_reinstall = "Reinstalace selhala";
        this.error_reinstall_confirmation = "Potvrzovací text nesouhlasí";

        this.success_delete = "Serverlet bude smazán";
        this.error_delete = "Serverlet se nepodařilo smazat";
        this.error_delete_confirmation = "Potvrzovací text nesouhlasí";


        this.loadRuntimes();
    }

    componentDidCache(){
        dropByCacheKey('Settings');
    }


    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.loaded && this.props.loaded){
            this.setState({
                loaded : this.props.loaded,
                title : this.props.loaded ? this.props.serverlet.title : "",
                auto_renew : this.props.loaded ? this.props.serverlet.auto_renew === 1 : null,
                runtime : this.props.loaded ? this.props.serverlet.runtime : null,
                plan : this.props.loaded ? this.props.serverlet.plan : null,
                lang: this.props.loaded ? { name : this.props.serverlet.lang } : null,
                langs: this.props.loaded ? this.props.serverlet.runtime.langs : null,
            });
        }
    }

    changeTitle(){
        Api.put(`/serverlet/${this.props.parent.state.params.id}/title`,{
            title : this.state.title
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    let serverletUpdated = this.props.serverlet;
                    serverletUpdated.title = this.state.title;
                    this.props.parent.setState({
                       serverlet : serverletUpdated
                    });
                    Api.show_message(this.success_rename, "success");
                }else{
                    if(res.error){
                        let mes = this.error_rename_generic;
                        mes = (res.error.list[0] === "empty") ? this.error_rename_empty: mes;
                        mes = (res.error.list[0] === "wrong-format") ? this.error_rename_format : mes;
                        mes = (res.error.list[0] === "invalid-length") ? this.error_rename_length: mes;
                        Api.show_message(mes, "error");
                    }
                }
            });
    }

    changeAutorenew(){
        Api.post(`/serverlet/${this.props.parent.state.params.id}/auto_renew`,{
            auto_renew : !this.state.auto_renew
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    let serverletUpdated = this.props.serverlet;
                    serverletUpdated.auto_renew = !serverletUpdated.auto_renew;
                    this.props.parent.setState({
                        serverlet : serverletUpdated
                    });
                    this.setState({
                        auto_renew : serverletUpdated.auto_renew
                    });
                    Api.show_message(this.success_auto_renew, "success");
                }else{
                    if(res.error){
                        Api.show_message(this.error_auto_renew, "error");
                    }
                }
            });
    }


    loadRuntimes(){
        Api.get("/serverlets/runtimes",{})
            .then(res => res.json())
            .then(data => {
                this.setState({
                    runtimes : data["runtimes"],
                });
            })
            .catch((error) => {});
    }


    selectRuntime(selectedRuntime){
        this.setState({
            runtime : selectedRuntime
        })
    };


    changeRuntime(event){
        if(event.target.classList.contains("loading")){
            return;
        }
        event.target.classList.add("loading");
        event.target.value = "Měním obraz...";
        Api.put(`/serverlet/${this.props.parent.state.params.id}/runtime`,{
            runtime : this.state.runtime.id
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    let serverletUpdated = this.props.serverlet;
                    serverletUpdated.runtime = this.state.runtime;
                    serverletUpdated.state = 1;
                    this.props.parent.setState({
                        serverlet : serverletUpdated,
                        checkbox : true
                    });
                    this.setState({
                        langs : this.state.runtime.langs
                    })
                    Api.show_message(this.success_runtime_change, "success");
                }else{
                    if(res.error){
                        let mes = this.error_runtime_change;
                        mes = (res.error.list[0] === "no-change") ? this.error_runtime_change_nochange: mes;
                        mes = (res.error.list[0] === "unavailable-runtime-lang") ? this.error_runtime_change_unavailable_lang : mes;
                        mes = (res.error.list[0] === "too-early") ? this.error_runtime_change_too_early : mes;
                        Api.show_message(mes, "error");
                    }
                }
                event.target.classList.remove("loading");
                event.target.value = "Změnit obraz";
            });
    }


    selectLang(selectedLang){
        this.setState({
            lang : selectedLang
        });
    };

    changeLang(){
        Api.put(`/serverlet/${this.props.parent.state.params.id}/lang`,{
            lang : this.state.lang.name
        })
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    let serverletUpdated = this.props.serverlet;
                    serverletUpdated.lang = this.state.lang.name;
                    this.props.parent.setState({
                        serverlet : serverletUpdated
                    });
                    Api.show_message(this.success_lang_change, "success");
                }else{
                    if(res.error){
                        let mes = this.error_lang_change;
                        mes = (res.error.list[0] === "no-change") ? this.error_lang_change_nochange : mes;
                        mes = (res.error.list[0] === "not-supported-lang") ? this.error_lang_change_notsupported : mes;
                        Api.show_message(mes, "error");
                    }
                }
            });
    };


    reinstall(){
        if(this.state.title.toLowerCase() !== this.state.reinstall_confirm.toLowerCase()){
            Api.show_message(this.error_reinstall_confirmation, "error");
            return;
        }
        Api.post(`/serverlet/${this.props.parent.state.params.id}/reinstall`,{})
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    Api.show_message(this.success_reinstall, "success");
                    this.setState({
                        reinstall_confirm : ""
                    })
                }else{
                    Api.show_message(this.error_reinstall, "error");
                }
            });
    };


    delete(){
        if(this.state.title.toLowerCase() !== this.state.delete_confirm.toLowerCase()){
            Api.show_message(this.error_delete_confirmation, "error");
            return;
        }
        Api.delete(`/serverlet/${this.props.parent.state.params.id}`,{})
            .then(response => response.json())
            .then(res => {
                if(res.success === true) {
                    dropByCacheKey('Serverlets');
                    Api.show_message(this.success_delete, "success");
                    this.setState({
                        delete_confirm : "",
                        deleting : true
                    })
                }else{
                    Api.show_message(this.error_delete, "error");
                }
            });
    }

    render(){
        let plans = [];
        if(this.plans.length > 0){
            this.plans.forEach(item => {
                plans.push(
                    <div data-id={item.id} onClick={this.selectPlan} className={(this.state.plan != null && this.state.plan.id === item.id) ? "option option-active" : "option"} key={item.id}>
                        <h1>{item["name"]}</h1>
                        <h5>{item.cpu}CPU</h5>
                        <h5>{item.ram}MB</h5>
                        <h5>{item.storage/1000}GB</h5>
                        <span className="title">{item.price} Kč/m</span>
                    </div>
                );
            });
        }
        if(this.state.deleting){
            return <Redirect to="/serverlets"/>
        }
        return (
            <Fragment>
                <div className="section">
                    <h1>Nastavení</h1>
                    <div className="section">
                        <div className="details-container">
                            <details className="linked-details">
                                <summary className="left-padding">
                                    <span className="icon">
                                        <img alt="Rename icon" src="/img/rename.svg"/>
                                    </span>
                                    <h1>Změna názvu</h1>
                                    <h2>Vhodný název usnadňuje orientaci</h2>
                                </summary>
                                <div className="body bg-white-darker">
                                    <label htmlFor="new_title">Název serverletu</label>
                                    <input value={this.state.title} onChange={this.handleInputChange} type="text" id="title" name="title"/>
                                    <input onClick={this.changeTitle} type="submit" name="title_submit" value="Změnit název"/><br/>
                                </div>
                            </details>

                            <details className="linked-details">
                                <summary className="left-padding linked">
                                    <span className="icon">
                                        <img alt="Plan icon" src="/img/plan.svg"/>
                                    </span>
                                    <h1>Změna tarifu</h1>
                                    <h2>Současný: { this.state.loaded ? this.props.serverlet.plan.name  : "..." }</h2>
                                </summary>
                                <div className="body">
                                    <p>V tuto chvíli není možné změnit tarif.</p>
                                </div>
                            </details>

                            <details className="linked-details">
                                <summary className="left-padding linked">
                                    <span className="icon">
                                        <img alt="Reinstall icon" src="/img/reinstall.svg"/>
                                    </span>
                                    <h1>Změna obrazu</h1>
                                    <h2>Současný: { this.state.loaded ? this.props.serverlet.runtime.name  : "..." }</h2>

                                </summary>
                                <div className="body">
                                    <p>Všechny obrazy nemusí být vzájemně kompatibilní, což si následně může vyžádat manuální zásah. Při změně obrazu bude serverlet restartován.</p>
                                    {
                                        this.props.loaded &&
                                        <SelectList
                                            options={this.state.runtimes}
                                            labelName="name"
                                            valueName="id"
                                            selectedOption={this.state.runtime}
                                            onChange={this.selectRuntime}
                                            noMessage="Obraz nenalezen"
                                            placeholder="Obraz"
                                        />
                                    }
                                    <input onClick={this.changeRuntime} type="submit" name="runtime_submit" value="Změnit obraz"/><br/>
                                </div>
                            </details>


                            <details className="linked-details">
                                <summary className="left-padding linked">
                                    <span className="icon">
                                        <img alt="Lang icon" src="/img/lang.svg"/>
                                    </span>
                                    <h1>Změna výchozího jazyka</h1>
                                    <h2>Současný: { this.state.loaded ? this.props.serverlet.lang  : "..." }</h2>
                                </summary>
                                <div className="body">
                                    <p>
                                        Změna výchozího jazyka ovlivní předpřipravenou ukázkovou aplikaci.<br/>
                                        V obrazu { this.state.loaded ? this.props.serverlet.runtime.name  : "..." } jsou dostupné následující jazyky:
                                    </p>
                                    {
                                        this.props.loaded &&
                                        <SelectList
                                            options={this.state.langs}
                                            labelName="name"
                                            valueName="name"
                                            selectedOption={this.state.lang}
                                            onChange={this.selectLang}
                                            noMessage="Jazyk nenalezen"
                                            placeholder="Jazyk"
                                        />
                                    }
                                    <input onClick={this.changeLang} type="submit" name="lang_submit" value="Změnit jazyk"/><br/>
                                </div>
                            </details>

                            <details className="linked-details">
                                <summary className="left-padding linked">
                                    <span className="icon">
                                        <img alt="Reinstall icon" src="/img/reinstall.svg"/>
                                    </span>
                                    <h1>Reinstalace</h1>
                                    <h2>Uvede serverlet do výchozího stavu</h2>
                                </summary>
                                <div className="body">
                                    <p>Reinstalace uvede serverlet do výchozího stavu. Důrazně doporučujeme provést zálohu Vašich dat před touto akcí.</p>
                                    <label htmlFor="reinstall_confirm">Potvrďte reinstalaci napsáním: {this.state.title}</label>
                                    <input onChange={this.handleInputChange} type="text" name="reinstall_confirm" id="reinstall_confirm" value={this.state.reinstall_confirm}/>
                                    <input onClick={this.reinstall} type="submit" name="reinstall_submit" value="Reinstalovat serverlet"/><br/>
                                </div>
                            </details>

                            <details className="linked-details">
                                <summary className="left-padding linked">
                                    <span className="icon">
                                        <img alt="Delete icon" src="/img/delete.svg"/>
                                    </span>
                                    <h1>Smazání</h1>
                                    <h2>Trvale smaže serverlet</h2>
                                </summary>
                                <div className="body">
                                    <p>Trvale smaže serverlet a všechna jeho data. Tato akce je nevratná. Důrazně doporučujeme si před smazáním vytvořit lokální zálohu dat ze serverletu.</p>
                                    <label htmlFor="delete_confirm">Potvrďte smazání napsáním: {this.state.title}</label>
                                    <input onChange={this.handleInputChange} type="text" name="delete_confirm" id="delete_confirm" value={this.state.delete_confirm}/>
                                    <input onClick={this.delete} type="submit" name="delete_submit" value="Smazat serverlet"/><br/>
                                </div>
                            </details>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default ServerletSettings;