import React, { Fragment } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { dropByCacheKey } from 'react-router-cache-route'
import Select from 'react-select';
import './Login.css';
import './Signup.css';
import VersionInfo from "../../components/VersionInfo/VersionInfo";

class Signup extends React.Component{
    constructor(props){
        super(props);
        this.loadState(this);
        this.state = {
            verify_error : false,
            verify_error_message : "Zadaný kód není správný",
            submit_loader : false,
            logged: false,
            step: 0,
            email: "",
            password: "",
            passwordAgain: "",
            personal: true,
            firstname: "",
            lastname: "",
            company: "",
            street: "",
            streetNo: "",
            postalCode: "",
            city: "",
            country: "",
            ic: "",
            dic: "",
            focused: [],
            selectedCountry : "",
            code1 : "",
            code2 : "",
            code3 : "",
            code4 : "",
            code5 : "",
            code6 : "",
            code : ""
        }
        this.codes = [1,2,3,4,5,6]
        this.codesRefs = [];

        this.email = React.createRef();
        this.password = React.createRef();
        this.passwordAgain = React.createRef();
        this.firstname = React.createRef();
        this.lastname = React.createRef();
        this.company = React.createRef();
        this.street = React.createRef();
        this.streetNo = React.createRef();
        this.postalCode = React.createRef();
        this.city = React.createRef();
        this.country = React.createRef();
        this.ic = React.createRef();
        this.dic = React.createRef();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleCodeSelect = this.handleCodeSelect.bind(this);
        this.handleKeyCode = this.handleKeyCode.bind(this);
        this.handleCodePaste = this.handleCodePaste.bind(this);
        props.router.cacheLifecycles.didCache(this.componentDidCache);

        this.customSelect = {
            control: base => ({
                ...base,
                height: 52,
                minHeight: 52
            }),
            valueContainer: provided => ({
                ...provided,
                height: 52,
                minHeight: 52
            }),
            input: base => ({
                position : "relative",
                top: -18
            }),
            option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#fff" : "#fff",
                color: "#202020",
                padding: "0.65em 1em",
                "&:hover": {
                    backgroundColor: "#fef2d1"
                }
            })
        };
        this.countryOptions = [
            { value: 'cz', label: 'Česká republika' },
            { value: 'sk', label: 'Slovensko' }
        ];
    }
    componentDidCache(){
        dropByCacheKey('Signup');
    }

    async loadState(parent){
        let output = await parent.props.auth.getSignupState();
        if(output.result){
            if(output.response.state === 1){
                parent.setState({ step : 1 })
            }
            if(output.response.state === 2){
                parent.setState({ step : 4 })
            }
        }else{
            if(output.response.blocked){
                parent.setState({ step : 7 })
            }else{
                parent.setState({ step : 6 })
            }
        }
    }

    handleInputChange(event) {
        this.setState({[event.target.name] : event.target.value});
        if(
            event.target.name === "email" ||
            event.target.name === "password" ||
            event.target.name === "passwordAgain"
        ){
            this.validateStep1();
        }
        if(
            event.target.name === "firstname" ||
            event.target.name === "lastname" ||
            event.target.name === "street" ||
            event.target.name === "streetNo" ||
            event.target.name === "postalCode" ||
            event.target.name === "city" ||
            event.target.name === "country" ||
            event.target.name === "company" ||
            event.target.name === "ic" ||
            event.target.name === "dic"
        ){
            this.validateStep2();
        }
    }

    handleCheckboxChange(event) {
        this.setState({
            [event.target.name] : (event.target.value === "true")
        });
    }

    handleInputFocus(event) {
        let focused_new = this.state.focused;
        focused_new[event.target.name] = true;
        this.setState({
            focused : focused_new
        })
    }

    handleInputBlur(event) {
        if(event.target.value === ""){
            let focused_new = this.state.focused;
            focused_new[event.target.name] = false;
            this.setState({
                focused : focused_new
            })
        }
    }

    async handleSubmit(event) {
        if(event.target.name === "back"){
            this.setState({
                step : this.state.step-1
            });
        }else{
            this.setState({
                submit_loader : true
            });
            if(event.target.name === "submit1"){
                if(this.canSubmitStep1()){
                    this.setState({
                        step : this.state.step+1,
                        submit_loader : false
                    });
                }
            }
            if(event.target.name === "submit2"){
                if(this.canSubmitStep2()){
                    this.setState({
                        step : this.state.step+1,
                        submit_loader : false
                    });
                }
            }
            if(event.target.name === "startover"){
                await this.startOver();
            }
            if(event.target.name === "verifycode"){
                await this.verifyCode();
            }
            if(event.target.name === "submit6"){
                this.startOver();
            }
            if(event.target.name === "submit3"){
                await this.signup();
            }
        }
    }

    async signup(){
        let output = await this.props.auth.signup(
            this.state.email,
            this.state.password,
            this.state.personal,
            this.state.firstname,
            this.state.lastname,
            this.state.company,
            this.state.street,
            this.state.streetNo,
            this.state.postalCode,
            this.state.city,
            this.state.country,
            this.state.ic,
            this.state.dic
        );
        this.setState({
            step : (output.result) ? 4 : 6,
            submit_loader : false
        });
    }

    handleCountryChange = (selectedOption) => {
        this.setState({
            selectedCountry : selectedOption,
            country : selectedOption.value
        });
    };

    handleCodePaste(event){
        event.clipboardData.items[0].getAsString(text=> {
            var chars = text.split('');
            this.setState({
                code1 : (chars[0]) ?? "",
                code2 : (chars[1]) ?? "",
                code3 : (chars[2]) ?? "",
                code4 : (chars[3]) ?? "",
                code5 : (chars[4]) ?? "",
                code6 : (chars[5]) ?? ""
            });
            this.codesRefs[6].current.focus();
        })
    }

    handleCodeSelect(event){
        let id = parseInt(event.target.getAttribute("data-id"));
        let old = this.state[event.target.name];
        this.setState({
            [event.target.name] : event.target.value
        });
        if(!old && id < 6){
            this.codesRefs[id + 1].current.focus();
        }
    }

    handleKeyCode(e){
        let id = parseInt(e.target.getAttribute("data-id"));
        if(e.keyCode === 8){
                let currentNum = this.state[e.target.name];
                if(!currentNum && id > 1){
                    this.codesRefs[id - 1].current.focus();
                }
        }
    }

    async startOver(){
        let output = await this.props.auth.resetSignupState();
        if(output.result){
            this.setState({
                step : 1,
                submit_loader : false,
                verify_error : false,
                logged: false,
                email: "",
                password: "",
                passwordAgain: "",
                personal: true,
                firstname: "",
                lastname: "",
                company: "",
                street: "",
                streetNo: "",
                postalCode: "",
                city: "",
                country: "",
                ic: "",
                dic: "",
                focused: [],
                selectedCountry : "",
                code1 : "",
                code2 : "",
                code3 : "",
                code4 : "",
                code5 : "",
                code6 : "",
                code : ""
            });
        }
    }

    async verifyCode(){
        let output = await this.props.auth.finishSignup(this.getCode());
        if(output.result){
            this.setState({
                step : 5,
                submit_loader : false
            });
        }else{
            this.setState({
                verify_error : true,
                submit_loader : false
            });
        }
    }

    getCode(){
        return this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4 + this.state.code5 + this.state.code6;
    }

    validateStep1(){
        let error = false;
        if(this.email.current.value.length > 0){
            if(this.email.current.value.indexOf("@") === -1 || this.email.current.value.indexOf(".") === -1 || this.email.current.value.split(".").slice(-1)[0].length === 0){
                this.email.current.classList.add("input-error");
                error = true;
            }else{
                this.email.current.classList.remove("input-error")
            }
        }else{
            this.email.current.classList.remove("input-error")
        }
        if(this.password.current.value.length > 0){
            if(this.password.current.value.length < 8){
                this.password.current.classList.add("input-error");
                error = true;
            }else{
                this.password.current.classList.remove("input-error")
            }
        }else{
            this.password.current.classList.remove("input-error")
        }
        if(this.passwordAgain.current.value.length > 0){
            if(this.passwordAgain.current.value !== this.password.current.value){
                this.passwordAgain.current.classList.add("input-error");
                error = true;
            }else{
                this.passwordAgain.current.classList.remove("input-error")
            }
        }
        return error;
    }

    validateStep2(){
        let error = false;
        if(this.state.personal){
            if(this.firstname.current.value.length > 0){
                if (!/^[\p{L}\s\-—]+$/ui.test(this.firstname.current.value)){
                    this.firstname.current.classList.add("input-error");
                    error = true;
                }else{
                    this.firstname.current.classList.remove("input-error");
                }
            }else{
                this.firstname.current.classList.remove("input-error");
            }

            if(this.lastname.current.value.length > 0){
                if (!/^[\p{L}\s\-—]+$/ui.test(this.lastname.current.value)){
                    this.lastname.current.classList.add("input-error");
                    error = true;
                }else{
                    this.lastname.current.classList.remove("input-error");
                }
            }else{
                this.lastname.current.classList.remove("input-error");
            }
        }else{
            if(this.ic.current.value.length > 0){
                if (!/^[0-9]+$/.test(this.ic.current.value) || this.ic.current.value.length > 8){
                    this.ic.current.classList.add("input-error");
                    error = true;
                }else{
                    this.ic.current.classList.remove("input-error");
                }
            }else{
                this.ic.current.classList.remove("input-error");
            }
        }
        if(this.street.current.value.length > 0){
            if (!/^[\p{L}\s\-—]+$/ui.test(this.street.current.value)){
                this.street.current.classList.add("input-error");
                error = true;
            }else{
                this.street.current.classList.remove("input-error");
            }
        }else{
            this.street.current.classList.remove("input-error");
        }

        if(this.streetNo.current.value.length > 0){
            if (!/^[0-9/]+$/.test(this.streetNo.current.value)){
                this.streetNo.current.classList.add("input-error");
                error = true;
            }else{
                this.streetNo.current.classList.remove("input-error");
            }
        }else{
            this.streetNo.current.classList.remove("input-error");
        }

        if(this.postalCode.current.value.length > 0){
            if (!/^[0-9]+$/.test(this.postalCode.current.value) || this.postalCode.current.value.length < 5){
                this.postalCode.current.classList.add("input-error");
                error = true;
            }else{
                this.postalCode.current.classList.remove("input-error");
            }
        }else{
            this.postalCode.current.classList.remove("input-error");
        }

        if(this.city.current.value.length > 0){
            if (!/^[\p{L}\s\-—]+$/ui.test(this.city.current.value)){
                this.city.current.classList.add("input-error");
                error = true;
            }else{
                this.city.current.classList.remove("input-error");
            }
        }else{
            this.city.current.classList.remove("input-error");
        }


        return error;
    }

    canSubmitStep1(){
        if(
            this.email.current == null ||
            this.password.current == null ||
            this.passwordAgain.current == null ||
            this.email.current.value.length === 0 ||
            this.password.current.value.length === 0 ||
            this.passwordAgain.current.value.length === 0
        ){
            return false;
        }
        return (!this.validateStep1());
    }

    canSubmitStep2(){
        if(
            this.street.current == null ||
            this.streetNo.current == null ||
            this.postalCode.current == null ||
            this.city.current == null ||
            this.country.current == null ||
            this.street.current.value.length === 0 ||
            this.streetNo.current.value.length === 0 ||
            this.postalCode.current.value.length === 0 ||
            this.city.current.value.length === 0 ||
            this.state.country.length === 0
        ){
            return false;
        }
        if(
            this.state.personal &&
            (
                this.firstname.current == null ||
                this.lastname.current == null ||
                this.firstname.current.value.length === 0 ||
                this.lastname.current.value.length === 0
            )
        ){
            return false;
        }
        if(
            !this.state.personal &&
            (
                this.company.current == null ||
                this.ic.current == null ||
                this.dic.current == null ||
                this.company.current.value.length === 0 ||
                this.ic.current.value.length === 0
            )
        ){
            return false;
        }
        return (!this.validateStep2());
    }

    render(){
        let codes = [];
        if(this.state.step === 4){
            this.codes.forEach(item =>{
                this.codesRefs[item] = React.createRef();
                codes.push(
                    <input type="text" onPaste={this.handleCodePaste} data-id={item} onKeyDown={this.handleKeyCode} ref={this.codesRefs[item]} name={"code" + item} maxLength="1" value={this.state["code" + item]} onChange={this.handleCodeSelect} placeholder="#"/>
                )
            })
        }
        if(this.props.auth.authenticated){
            return <Redirect to="/"/>;
        }else{
            return(
                <Fragment>
                    <div className="login-container">
                        <div className="form-page">
                            <div className="form-box">
                                {
                                    (this.state.step >= 1 && this.state.step <= 4) &&
                                    <span className="step">krok {this.state.step} / 4</span>
                                }
                                {
                                    (this.state.step >= 1 && this.state.step <= 3) &&
                                    <h2>Vytvoření účtu</h2>
                                }
                                {
                                    this.state.step === 4 &&
                                    <h2>Ověření účtu</h2>
                                }
                                {
                                    this.state.step === 5 &&
                                    <Fragment>
                                        <span className="top-success"/>
                                        <h2>Registrace dokončena</h2>
                                    </Fragment>                            }
                                {
                                    this.state.step === 6 &&
                                    <Fragment>
                                        <span className="top-error"/>
                                        <h2>Registrace se nezdařila</h2>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 7 &&
                                    <Fragment>
                                        <span className="top-error"/>
                                        <h2>Registrace zablokována</h2>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 0 &&
                                    <Fragment>
                                        <div className="loader"></div>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 1 &&
                                        <Fragment>
                                            <h3>Zadejte své budoucí přihlašovací údaje</h3>
                                            <input id="email" value={this.state.email} onChange={this.handleInputChange} type="text" className="email-icon" placeholder="E-mail" name="email" autoComplete="off" ref={this.email} required/>
                                            <input value={this.state.password} onChange={this.handleInputChange} type="password" className="password-icon" name="password" autoComplete="off" placeholder="Heslo" ref={this.password} required/>
                                            <input value={this.state.passwordAgain} onChange={this.handleInputChange} type="password" className="password-icon" name="passwordAgain" autoComplete="off" placeholder="Heslo znovu" ref={this.passwordAgain} required/>
                                            <input type="submit" name="submit1" value="Pokračovat" className={(this.canSubmitStep1()) ? "next-icon" : "next-icon input-disabled"} onClick={this.handleSubmit}/>
                                        </Fragment>
                                }
                                {
                                    this.state.step === 2 &&
                                    <Fragment>
                                        <h3>Zadejte své fakturační údaje</h3>
                                        <span className="toggle-btn">
                                            <input id="toggle-on" onChange={this.handleCheckboxChange} className="toggle toggle-left" name="personal" value={true} type="radio" checked={this.state.personal}/>
                                            <label htmlFor="toggle-on" className="btn">Osoba</label>
                                            <input id="toggle-off" onChange={this.handleCheckboxChange} className="toggle toggle-right" name="personal" value={false} type="radio" checked={!this.state.personal}/>
                                            <label htmlFor="toggle-off" className="btn">Firma</label>
                                        </span>
                                        {
                                            this.state.personal &&
                                            <Fragment>
                                                <input value={this.state.firstname} onChange={this.handleInputChange} type="text" className="split2 split-space" name="firstname" autoComplete="off" placeholder="Jméno" ref={this.firstname} required/>
                                                <input value={this.state.lastname} onChange={this.handleInputChange} type="text" className="split2" name="lastname" autoComplete="off" placeholder="Příjmení"  ref={this.lastname} required/>
                                            </Fragment>
                                        }
                                        {
                                            !this.state.personal &&
                                            <Fragment>
                                                <input value={this.state.company} onChange={this.handleInputChange} type="text" className="" name="company" autoComplete="off" placeholder="Společnost" ref={this.company} required/>
                                            </Fragment>
                                        }
                                        <span className="delimiter"/>
                                        <input value={this.state.street} onChange={this.handleInputChange} type="text" className="split13 split-space" name="street" autoComplete="off" placeholder="Ulice" ref={this.street} required/>
                                        <input value={this.state.streetNo} onChange={this.handleInputChange} type="text" className="split3" name="streetNo" autoComplete="off" placeholder="č.p / č.o." ref={this.streetNo} required/>

                                        <input value={this.state.postalCode} onChange={this.handleInputChange} type="text" className="split4 split-space" name="postalCode" autoComplete="off" placeholder="PSČ" maxLength="5" ref={this.postalCode} required/>
                                        <input value={this.state.city} onChange={this.handleInputChange} type="text" className="split14" name="city" autoComplete="off" placeholder="Obec" ref={this.city} required/>

                                        <Select
                                            value={this.state.selectedCountry}
                                            onChange={this.handleCountryChange}
                                            options={this.countryOptions}
                                            height="52px"
                                            placeholder="Stát"
                                            className="select"
                                            classNamePrefix="select"
                                            noOptionsMessage={({inputValue}) => !inputValue ? inputValue : "Stát nenalezen"}
                                            styles={this.customSelect}
                                            ref={this.country}
                                        />

                                        {
                                            !this.state.personal &&
                                            <Fragment>
                                                <input value={this.state.ic} onChange={this.handleInputChange} type="text" className="split2 split-space" name="ic" autoComplete="off" placeholder="IČ" ref={this.ic} maxLength="8" required/>
                                                <input value={this.state.dic} onChange={this.handleInputChange} type="text" className="split2" name="dic" autoComplete="off" placeholder="DIČ" ref={this.dic} maxLength="12" required/>
                                            </Fragment>
                                        }
                                        <input type="submit" name="back" className="prev prev-icon" value="Zpět" onClick={this.handleSubmit}/>
                                        <input type="submit" name="submit2"  className={(this.canSubmitStep2()) ? "next next-icon" : "next next-icon input-disabled"} value="Pokračovat" onClick={this.handleSubmit}/>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 3 &&
                                    <Fragment>
                                        <h3>Souhlas s podmínkami služby</h3>
                                        <p>Kliknutím na tlačítko Dokončit potvrzujete, že jste četl/a a berete na vědomí <a href="https://futrou.com/terms-of-service" target="_blank" rel="noreferrer">podmínky služby</a> a <a href="https://futrou.com/terms-of-service" target="_blank" rel="noreferrer">zásady ochrany osobních údajů</a>.</p>
                                        <p className="with-icon"><span className="shield"/> Prohlašujeme, že Vám z naší strany nebudeme zasílat žádné nevyžádané e-maily, které přímo nesouvisí s Vaším účtem.</p>

                                        <input type="submit" name="back" className="prev prev-icon" value="Zpět" onClick={this.handleSubmit}/>
                                        <input type="submit" name="submit3" className={ this.state.submit_loader ? "next next-loader" : "next next-icon"} value="Dokončit" onClick={this.handleSubmit}/>

                                 </Fragment>
                                }
                                {
                                    this.state.step === 4 &&
                                    <Fragment>
                                        <div className="email-verification">
                                            Zadejte prosím ověřovací kód, který jsme Vám právě zaslali na e-mail.
                                            {(this.state.verify_error) ? <div className="error">{this.state.verify_error_message}</div> : <Fragment/> }
                                            <div className="code-input">
                                                {codes}
                                            </div>
                                            <div className="bottom_container">
                                                <input type="submit" name="startover" className="reset prev-icon" value="Začít znovu" onClick={this.handleSubmit}/>
                                                <input type="submit" name="verifycode" className={ this.state.submit_loader ? "finish next-loader" : "finish next-icon"} value="Ověřit" onClick={this.handleSubmit}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 5 &&
                                    <Fragment>
                                        <div className="email-verification">
                                            <p>A je to! Děkujeme Vám za registraci. Nyní už se můžete pustit do užívání svého účtu.</p>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 6 &&
                                    <Fragment>
                                        <div className="email-verification">
                                            <p>Během registrace došlo k chybě. Zkuste to prosím znovu později. Pokud problém přetrvává, kontaktujte nás.</p>
                                            <div className="bottom_container">
                                                <input type="submit" name="submit6" className="prev-icon" value="Začít znovu" onClick={this.handleSubmit}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    this.state.step === 7 &&
                                    <Fragment>
                                        <div className="email-verification">
                                            <p>Vaše zařízení bylo dočasně zablokováno, z důvodu příliš mnoha provedených pokusů. Zkuste to prosím znovu za několik minut.</p>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                            <div className="bottom">
                                <Link to="/login">Už účet mám</Link>
                            </div>
                        </div>
                    </div>
                    <VersionInfo/>
                </Fragment>
            )
        }
    }
}
export default Signup;